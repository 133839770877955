import React, {useState} from 'react';
import {Form, Input, Message, Button, Grid, GridRow, Icon} from 'semantic-ui-react';
import ApiService from '../services/ApiService';
import { useAuth } from '../context/auth';
import ReactPlayer from 'react-player';

const api = new ApiService();

const MovieCreator = (props) => {
    const [movie, setMovie ] = useState("");
    const [displayMovie, setDisplayMovie] = useState(false);
    const [movieBlob, setMovieBlob] = useState();
    const [success, setSuccess] = useState(false);
    const [error, setError] = useState(false);
    const [loading, setLoading] = useState(false);
    const { authTokens } = useAuth();
    const headers =  {
        "X-Firebase-Auth-Client":  authTokens.token
    }


    const handleSubmit = async () => {
        setLoading(true);
        let formData = new FormData()
        formData.append("multipartFile",movieBlob);
        try{
            await api.addMovie(formData, headers);
            setLoading(false);
            setSuccess(true);
        }catch(error){
            setLoading(false);
            setError(true);
        }
        
    }

    const removeMovie = () => {
        setDisplayMovie(false);
        setMovie();
    }

    const movieChange = (e) => {
        setDisplayMovie(true);
        setMovie(URL.createObjectURL(e.target.files[0]));
        setMovieBlob(e.target.files[0]);
    }

    return(
        <Grid padded>
            <Form onSubmit={handleSubmit} success={success} loading={loading} error={error}>
                 <Form.Field>
                    <label>Movie</label>
                    {displayMovie ? <Grid padded columns={1}><GridRow><Icon onClick={removeMovie}  name='delete'/> </GridRow><GridRow><ReactPlayer url={movie} /></GridRow></Grid>: <Input type='file' onChange={movieChange} />} 
                </Form.Field>
                <Message
                    success
                    header='Completed'
                    content="New movie added"
                    />
                <Message
                    error
                    header='Failed'
                    content="Failed to add new movie"
                    />
                <Button color='green' basic type='submit'>Submit</Button>
            </Form>

        </Grid>
    )
}

export default MovieCreator;