import React, { useEffect, useState } from 'react'
import ReactPlayer from 'react-player';
import { TabView, TabPanel } from 'primereact/tabview';

import ApiService from '../services/ApiService';
import MovieCreator from './MovieCreator';
import { useAuth } from '../context/auth';

import './movies.css'

const api = new ApiService();

const Movies = () => {
    const [getAllMovies, setGetAllMovies] = useState([]); 
    const { authTokens } = useAuth();
    
    useEffect(() => {
        const headers = {
            'X-Firebase-Auth-Client': authTokens.token,
        };

        const getMovies = async () => {
            const response = await api.getAllMovies(headers);
            setGetAllMovies(response.data);
            console.log(response.data);
        }

        getMovies();
    }, [authTokens]);

    return (
        <div>
            <TabView>
                <TabPanel header="All movies">
                    <div className='movies-list'>
                        {getAllMovies.map((movie, index) => (
                            // only show movie if movie.url is not null
                            movie.url && 
                            <div className='movies-list-item' key={index}>
                                <ReactPlayer controls width='180px' height='320px' url={movie.url}></ReactPlayer>
                                <span>{movie.originalName}</span>
                            </div>
                        ))}
                    </div>
                </TabPanel>
                <TabPanel header="Add movie">
                    <MovieCreator />
                </TabPanel>
            </TabView>
        </div>
    )
}

export default Movies