import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import 'semantic-ui-css/semantic.min.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import { ClearCacheProvider } from 'react-clear-cache';
import 'primeflex/primeflex.css'; 

ReactDOM.render(
    <ClearCacheProvider auto={true}>
        <App />
    </ClearCacheProvider>
, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
