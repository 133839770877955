import React from 'react';

const RequiredMessage = ({ message }) => {
	return (
		<div className="flex align-items-center justify-content-start p-invalid">
			<i className="pi pi-exclamation-triangle" style={{ color: '#e24c4c' }}></i>
			<small className="ml-2 p-error">{message}</small>
		</div>
	);
};

export default RequiredMessage;
