import React, { useEffect, useRef, useState } from 'react';
import { FilterMatchMode } from 'primereact/api';
import { Button } from 'primereact/button';
import { TabView, TabPanel } from 'primereact/tabview';
import { Toast } from 'primereact/toast';
import { Dialog } from 'primereact/dialog';

import ApiService from '../services/ApiService';
import { useAuth } from '../context/auth';
import ActiveCodes from './ActiveCodes';
import RevokedCodes from './RevokedCodes';
import CodeDialog from './CodeDialog';
import PromotionDialog from './PromotionDialog';

const api = new ApiService();
const dateFormat = { hour: 'numeric', minute: 'numeric', year: 'numeric', month: 'numeric', day: 'numeric' };

const Code = () => {
	const emptyCode = {
		name: '',
		description: '',
		maxClaims: 0,
		maxClaimsPerUser: 0,
		startTime: '',
		endTime: '',
		promotionId: '',
	};

	const emptyPromotion = {
		type: '',
		value: '',
		unit: '',
	};

	const toast = useRef(null);

	const [code, setCode] = useState(emptyCode);
	const [codes, setCodes] = useState([]);
	const [codeDialog, setCodeDialog] = useState(false);
	const [submittedCode, setSubmittedCode] = useState(false);
	const [revokedCodes, setRevokedCodes] = useState([]);
	const [globalFilterValue, setGlobalFilterValue] = useState('');
	const [filters, setFilters] = useState({
		global: { value: null, matchMode: FilterMatchMode.CONTAINS },
	});
	const [codeDialogTitle, setCodeDialogTitle] = useState('');

	const [promotion, setPromotion] = useState({
		id: '',
		type: '',
		value: '',
		unit: '',
	});
	const [selectedCode, setSelectedCode] = useState(null);
	const [promotionOptions, setPromotionOptions] = useState([]);
	const [promotions, setPromotions] = useState([]);

	const [promotionDialog, setPromotionDialog] = useState(false);
	const [submittedPromotion, setSubmittedPromotion] = useState(false);

	const { authTokens } = useAuth();

	useEffect(() => {
		const headers = {
			'X-Firebase-Auth-Client': authTokens.token,
		};
		const getPromotions = async () => {
			const result = await api.getPromotions(headers);
			const options = result.data.map((promotion) => ({
				key: promotion.id,
				text: promotion.type + '_' + promotion.value + promotion.unit,
				value: promotion.id,
			}));

			setPromotionOptions(options);
			setPromotions(result.data);
		};

		getPromotions();

		return () => {
			setPromotionOptions([]);
			setPromotions([]);
		};
	}, [submittedCode, submittedPromotion]);

	useEffect(() => {
		const getCodes = async () => {
			const headers = {
				'X-Firebase-Auth-Client': authTokens.token,
			};

			const response = await api.getCodes(headers);

			// convert start date and end date to readable format
			response.data.forEach((code) => {
				code.startTime = new Date(code.startTime).toLocaleDateString('be-NL', dateFormat);
				code.endTime = new Date(code.endTime).toLocaleDateString('be-NL', dateFormat);
			});

			setCodes(response.data);
		};

		const getRevokedCodes = async () => {
			const headers = {
				'X-Firebase-Auth-Client': authTokens.token,
			};

			const response = await api.getRevokedCodes(headers);

			// convert start date and end date to readable format
			response.data.forEach((code) => {
				code.startTime = new Date(code.startTime).toLocaleDateString('be-NL', dateFormat);
				code.endTime = new Date(code.endTime).toLocaleDateString('be-NL', dateFormat);
			});

			setRevokedCodes(response.data);
		};

		getCodes();
		getRevokedCodes();

		return () => {
			setCodes([]);
			setRevokedCodes([]);
		};
	}, []);

	const onGlobalFilterChange = (e) => {
		const value = e.target.value;
		let _filters = { ...filters };

		_filters['global'].value = value;

		setFilters(_filters);
		setGlobalFilterValue(value);
	};

	const handleShowMore = (e) => {
		let currentCode = codes.find((code) => code.id === e.target.id);
		setSelectedCode(currentCode);
	};

	const handleShowMoreRevoked = (e) => {
		let currentCode = revokedCodes.find((code) => code.id === e.target.id);
		setSelectedCode(currentCode);
	};

	const getSeverity = (status) => {
		switch (status) {
			case 'FREE':
				return 'success';
			case 'REDUCTION':
				return 'warning';
			default:
				return 'info';
		}
	};

	const descriptionBodyTemplate = (rowData) => {
		return (
			<div className="flex align-items-center">
				<span className="text-sm font-bold mr-2 block">{rowData.name}</span>

				{rowData.description && (
					<Button
						rounded
						text
						className="flex align-items-center justify-content-center"
						size="small"
						onClick={rowData.revoked ? handleShowMoreRevoked : handleShowMore}
						id={rowData.id}
						style={{ height: '1.2rem', width: '1.2rem', padding: '0.2rem' }}
					>
						<i className="pi pi-info-circle" id={rowData.id}></i>
					</Button>
				)}
			</div>
		);
	};

	return (
		<>
			<Toast ref={toast} />

			<TabView>
				<TabPanel header="Active codes">
					<div className="mt-5 main-datatable">
						<ActiveCodes
							setSubmittedCode={setSubmittedCode}
							setPromotion={setPromotion}
							setSubmittedPromotion={setSubmittedPromotion}
							setCode={setCode}
							codes={codes}
							setCodes={setCodes}
							revokedCodes={revokedCodes}
							setRevokedCodes={setRevokedCodes}
							filters={filters}
							getSeverity={getSeverity}
							descriptionBodyTemplate={descriptionBodyTemplate}
							toast={toast}
							onGlobalFilterChange={onGlobalFilterChange}
							globalFilterValue={globalFilterValue}
							setPromotionDialog={setPromotionDialog}
							setCodeDialog={setCodeDialog}
							emptyCode={emptyCode}
							emptyPromotion={emptyPromotion}
							setCodeDialogTitle={setCodeDialogTitle}
						/>
					</div>
				</TabPanel>

				<TabPanel header="Revoked codes">
					<div className="mt-5 main-datatable">
						<RevokedCodes
							filters={filters}
							revokedCodes={revokedCodes}
							getSeverity={getSeverity}
							descriptionBodyTemplate={descriptionBodyTemplate}
							toast={toast}
							onGlobalFilterChange={onGlobalFilterChange}
							globalFilterValue={globalFilterValue}
						/>
					</div>
				</TabPanel>
			</TabView>

			{selectedCode && (
				<Dialog
					visible={true}
					style={{ width: '30vw' }}
					header={
						<div className="flex-column justify-content-start align-items-start">
							<span className="text-lg font-bold block">{selectedCode.name}</span>
							<span className="text-sm font-light font-italic">Promotion: {selectedCode.promotion.type}</span>
						</div>
					}
					onHide={() => setSelectedCode(null)}
				>
					<div className="flex-column align-items-start justify-content-start">
						<span className="block line-line-height-2">{selectedCode.description}</span>
					</div>
				</Dialog>
			)}

			<CodeDialog
				code={code}
				setCode={setCode}
				codeDialog={codeDialog}
				setCodeDialog={setCodeDialog}
				setSubmittedCode={setSubmittedCode}
				promotions={promotions}
				setPromotion={setPromotion}
				submittedCode={submittedCode}
				promotion={promotion}
				promotionOptions={promotionOptions}
				codes={codes}
				setCodes={setCodes}
				dateFormat={dateFormat}
				toast={toast}
				codeDialogTitle={codeDialogTitle}
			/>
			<PromotionDialog
				promotionDialog={promotionDialog}
				submittedPromotion={submittedPromotion}
				setSubmittedPromotion={setSubmittedPromotion}
				setPromotionDialog={setPromotionDialog}
				toast={toast}
				promotionOptions={promotionOptions}
				setPromotionOptions={setPromotionOptions}
			/>
		</>
	);
};

export default Code;
