import React, { useContext, useEffect, useState, createRef } from 'react';
import S3 from 'aws-sdk/clients/s3';
import { Dropdown } from 'primereact/dropdown';
import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';
import { TabMenu } from 'primereact/tabmenu';
import { Card, Divider, Image, Label, Menu, Message, Sticky } from 'semantic-ui-react';
import Spinner from '../loading/Spinner';
import LoadingContext from '../loading/LoadingContext';
import { useAuth } from '../context/auth';
import ApiService from '../services/ApiService';
import { Link } from 'react-router-dom/cjs/react-router-dom.min';
import './Machine.css';

const api = new ApiService();

function MachinePrtScState() {
	const prefixLength = 'machine-screen-states/'.length;
	const { authTokens } = useAuth();
	const headers = { 'X-Firebase-Auth-Client': authTokens.token };

	const s3 = new S3({
		accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY_ID,
		secretAccessKey: process.env.REACT_APP_AWS_SECRET_ACCESS_KEY,
		region: process.env.REACT_APP_AWS_REGION,
	});

	const [images, setImages] = useState([]);
	const [searchItem, setSearchItem] = useState('');
	const [searchResults, setSearchResults] = useState([]);
	const [typeFilter, setTypeFilter] = useState('Available');
	const [error, setError] = useState(null);
	const [machines, setMachines] = useState([]);
	const { showLoading, hideLoading } = useContext(LoadingContext);

	const handleSearch = (e) => {
		setSearchItem(e.target.value);
		console.log(e.target.value);

		const filteredImages = images.filter((image) => {
			const machineName = image.machine?.name || image.Key.split('.').slice(0, -1).join('.').substring(prefixLength);
			const customerName =
				image.machine?.customerName || image.Key.split('.').slice(0, -1).join('.').substring(prefixLength);

			const matchesType =
				typeFilter === 'Available' ? image.machine?.availableForCustomer : !image.machine?.availableForCustomer;

			// if the type is available, only show the available machines and if the type is unavailable, only show the unavailable machines

			return (
				(customerName.toLowerCase().includes(e.target.value.toLowerCase()) && matchesType) ||
				(machineName.toLowerCase().includes(e.target.value.toLowerCase()) && matchesType)
			);
		});

		console.log('filteredImages', filteredImages);

		setSearchResults(filteredImages);
	};

	const onHandleClearFilters = () => {
		setSearchItem('');

		const filteredImages = images.filter((image) => {
			const matchesType =
				typeFilter === 'Available' ? image.machine?.availableForCustomer : !image.machine?.availableForCustomer;

			return matchesType;
		});

		setSearchResults(filteredImages);
	};

	const handleTypeFilterChange = (e) => {
		setTypeFilter(e.value);

		const filteredImagesByType = images.filter((image) => {
			if (!image.machine) return;
			console.log('image', image);

			if (e.value === 'Available') {
				return image.machine.availableForCustomer;
			} else if (e.value === 'Unavailable') {
				return !image.machine.availableForCustomer;
			}
		});

		setSearchResults(filteredImagesByType);
	};

	useEffect(() => {
		if (!machines.length) return;
		const fetchImages = async () => {
			try {
				const data = await s3
					.listObjectsV2({
						Bucket: 'static.alberts.be',
						Prefix: 'machine-screen-states/',
					})
					.promise();

				// link images to machines
				data.Contents.forEach((image) => {
					// find machine by machineId and image name (which is the machineId + .png)
					const machine = machines.find((machine) => {
						return machine.machineId === image.Key.split('.').slice(0, -1).join('.').substring(prefixLength);
					});

					// if machine is found, add machine object to the image object
					if (machine) {
						image.machine = machine;
					}
				});

				setError(null);

				// remove first item from array (which is the folder itself)
				data.Contents.shift();

				// sort images by machine name alfabeticly
				data.Contents.sort((a, b) => {
					const machineName = a.machine?.name || a.Key.split('.').slice(0, -1).join('.').substring(prefixLength);

					return machineName.localeCompare(
						b.machine?.name || b.Key.split('.').slice(0, -1).join('.').substring(prefixLength),
					);
				});

				// already filter on type
				const filteredImagesByType = data.Contents.filter((image) => {
					if (!image.machine) return;

					if (typeFilter === 'Available') {
						return image.machine.availableForCustomer;
					} else if (typeFilter === 'Unavailable') {
						return !image.machine.availableForCustomer;
					}
				});

				setSearchResults(filteredImagesByType);
				setImages(data.Contents);
				console.log('images', data.Contents);
			} catch (err) {
				console.error(err, err.stack);
				setError(err);
			}

			hideLoading();
		};

		fetchImages();

		return () => {
			setImages([]);
			setSearchResults([]);
		};
	}, [machines]);

	useEffect(() => {
		const fetchAll = async () => {
			showLoading();
			const response = await api.getMachines(headers);
			setMachines(response.data);
		};

		fetchAll();
	}, []);

	const items = [
		{
			label: 'Machines',
			icon: 'pi pi-fw pi-home',
			command: () => {
				window.location = '/machines';
			},
		},
		{ label: 'Operations screen state', icon: 'pi pi-fw pi-cog' },
	];

	return (
		<>
			<Spinner />

			<div className="machine-sticky">
				<TabMenu model={items} activeIndex={1} />
			</div>

			<div className="mt-3">
				<div className="search flex align-items-center justify-content-between">
					<div className="flex align-items-center">
						<span className="p-input-icon-left">
							<i className="pi pi-search" />
							<InputText placeholder="Search" value={searchItem} onChange={(e) => handleSearch(e)} />
						</span>

						{searchItem !== '' && (
							<Button
								label="Clear search"
								icon="pi pi-times"
								className="p-button-danger w-full p-button-text"
								onClick={onHandleClearFilters}
							/>
						)}
					</div>

					<Dropdown
						placeholder="Filter on type"
						options={['Available', 'Unavailable']}
						value={typeFilter}
						onChange={handleTypeFilterChange}
					/>
				</div>

				{error && <Message error header="There has been an error" content={error?.message} />}

				<Card.Group stackable>
					{searchResults.map((image, index) => {
						const currentDateTime = new Date();

						return (
							<Card key={index}>
								<Card.Content extra>
									<Card.Header className="flex align-items-center justify-content-between">
										{image.machine?.name || image.Key.split('.').slice(0, -1).join('.').substring(prefixLength)}

										{image.machine?.ipOpenvpn && (
											<>
												{/* TODO: create toast message saying that they should make sure that openVPN is enabled s */}
												<a
													href={`http://${image.machine.ipOpenvpn}`}
													target="_blank"
													rel="noopener noreferrer"
													className="ui mini basic button"
												>
													{image.machine.ipOpenvpn}
												</a>
											</>
										)}
									</Card.Header>

									<Card.Meta>{image.machine && image.machine?.customerName}</Card.Meta>

									<Divider />

									{currentDateTime - image.LastModified > 3600000 && (
										<div>
											<Label basic color="red" pointing="below" size="mini">
												<i className="exclamation triangle icon"></i>
												Outdated: {'>'} 1 hour
											</Label>
										</div>
									)}

									<Label
										style={{ float: 'left' }}
										color={currentDateTime - image.LastModified > 3600000 ? 'red' : 'green'}
									>
										<i className="clock icon"></i>
										{image.LastModified.toLocaleTimeString()}
									</Label>

									<Label style={{ float: 'right' }}>
										<i className="calendar icon"></i>
										{image.LastModified.toLocaleDateString()}
									</Label>
								</Card.Content>
								<Image src={`https://s3.eu-west-1.amazonaws.com/static.alberts.be/${image.Key}`} wrapped ui={false} />
								<Card.Content extra>
									<a
										href={`https://s3.eu-west-1.amazonaws.com/static.alberts.be/${image.Key}`}
										target="_blank"
										rel="noopener noreferrer"
									>
										<i className="external alternate icon"></i>
										Full screen
									</a>
								</Card.Content>
							</Card>
						);
					})}
				</Card.Group>

				{!searchResults.length && !error && searchItem !== '' && (
					<Message info>
						<Message.Header>No results</Message.Header>
						<p>There are no results for your search.</p>
					</Message>
				)}
			</div>
		</>
	);
}

export default MachinePrtScState;
