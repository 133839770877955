import React from 'react';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { InputText } from 'primereact/inputtext';
import { Tag } from 'primereact/tag';

const RevokedCodes = ({
	revokedCodes,
	filters,
	getSeverity,
	descriptionBodyTemplate,
	onGlobalFilterChange,
	globalFilterValue,
}) => {
	const header = (
		<div className="flex align-items-center justify-content-between">
			<span className="p-input-icon-left">
				<i className="pi pi-search" />
				<InputText value={globalFilterValue} onChange={onGlobalFilterChange} placeholder="Keyword Search" />
			</span>
		</div>
	);

	return (
		<>
			<DataTable
				value={revokedCodes}
				paginator
				rows={10}
				rowsPerPageOptions={[10, 25, 50]}
				removableSort
				filters={filters}
				emptyMessage="No codes found"
				header={header}
				currentPageReportTemplate="Showing {first} to {last} of {totalRecords} products"
				paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
				tableStyle={{ minWidth: '60rem' }}
				globalFilterFields={[
					'name',
					'description',
					'maxClaims',
					'maxClaimsByUser',
					'startTime',
					'endTime',
					'totalClaimed',
					'promotion.type',
				]}
				size="normal"
			>
				<Column
					field="name"
					header="Codes"
					sortable
					style={{ minWidth: '5rem' }}
					body={descriptionBodyTemplate}
				></Column>
				<Column field="maxClaims" header="Max" sortable style={{ minWidth: '5rem' }}></Column>
				<Column field="maxClaimsByUser" header="Max per user" sortable style={{ minWidth: '5rem' }}></Column>
				<Column field="startTime" header="Start date" sortable style={{ minWidth: '5rem' }}></Column>
				<Column field="endTime" header="End date" sortable style={{ minWidth: '5rem' }}></Column>
				<Column field="totalClaimed" header="Total claimed" sortable style={{ minWidth: '5rem' }}></Column>
				<Column
					field="promotion?.type"
					header="Promotion"
					sortable
					style={{ minWidth: '5rem' }}
					body={(rowData) => <Tag severity={getSeverity(rowData.promotion?.type)}>{rowData.promotion?.type}</Tag>}
				></Column>
			</DataTable>
		</>
	);
};

export default RevokedCodes;
