import React, { useState } from 'react'
import PropTypes from 'prop-types'
import ErrorContext from './ErrorContext'

const ErrorProvider = ({ children }) => {
  const setErrorMessage = ({ errorMessage }) => {
    updateError((prevState) => {
      return {
        ...prevState,
        errorMessage: errorMessage
      }
    })
  }

  const errorState = {
    errorMessage: '',
    setErrorMessage
  }

  const [error, updateError] = useState(errorState)

  return (
    <ErrorContext.Provider value={error}>
      {children}
    </ErrorContext.Provider>
  )
}

ErrorProvider.propTypes = {
  children: PropTypes.any
}

export default ErrorProvider
