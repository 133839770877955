import React, { useCallback, useEffect, useState } from 'react';
import ApiService from '../services/ApiService';
import { useAuth } from '../context/auth';
import { Button, Card, Container, Grid, Header, Icon, Image, Input, Label, Message } from 'semantic-ui-react';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
const api = new ApiService();

function Ingredient() {
	const [all, setAll] = useState([]);
	const [amountOfResults, setAmountOfResults] = useState();
	const [searchFilter, setSearchFilter] = useState('');
	let amountOfResultsAferFilter = [];

	const history = useHistory();
	const { authTokens } = useAuth();

	useEffect(() => {
		const headers = {
			'X-Firebase-Auth-Client': authTokens.token,
		};
		const fetchIngredients = async () => {
			try {
				const response = await api.getAllIngredients(headers);

				// sort alphabetically
				response.data.sort((a, b) => {
					return a.name.localeCompare(b.name);
				});

				setAll(response.data);
			} catch (error) {
				console.error(error);
			}
		};

		fetchIngredients();

		return () => {
			setAll([]);
		};
	}, [authTokens.token]);

	const editIngredients = (ingredient) => {
		history.push('/ingredient/update', ingredient);
	};

	const editFlavor = (flavor) => {
		history.push('/flavor/update', flavor);
	};

	useEffect(() => {
		setAmountOfResults(amountOfResultsAferFilter.length);
	}, [amountOfResultsAferFilter]);

	const searchFilterMachines = async (e) => {
		setSearchFilter(e.target.value);
	};

	const onHandleClearFilters = () => {
		setSearchFilter('');
	};

	return (
		<div>
			<Grid columns={2} padded>
				<Grid.Row>
					<Grid.Column>
						<Header as="h2">Ingredients</Header>
					</Grid.Column>

					<Grid.Column textAlign="right" floated="right">
						<Button
							onClick={() => history.push('/ingredient/create')}
							content="Create"
							icon="plus"
							labelPosition="left"
							primary
						/>
					</Grid.Column>
				</Grid.Row>
			</Grid>

			<Grid padded stackable>
				<Grid.Row>
					<Grid.Column style={{ marginBottom: '10px' }} mobile={16} tablet={8} computer={10}>
						<Label color="black" size="large" style={{ float: 'left' }}>
							<Icon name="filter" />
							{amountOfResults} results
						</Label>
					</Grid.Column>

					<Grid.Column style={{ marginBottom: '10px' }} mobile={16} tablet={8} computer={6}>
						<Input
							style={{ width: '100%' }}
							value={searchFilter}
							icon="search"
							placeholder="Search..."
							onChange={(e) => searchFilterMachines(e)}
						/>
					</Grid.Column>

					{searchFilter !== '' ? (
						<Grid.Column style={{ marginBottom: '10px' }} width={16}>
							<Button onClick={onHandleClearFilters} content="Clear all filters" floated="right" />
						</Grid.Column>
					) : null}
				</Grid.Row>
			</Grid>

			<Grid>
				{all
					?.filter((ingredient) => {
						if (searchFilter === '') {
							return ingredient;
						} else if (ingredient.name.toLowerCase().includes(searchFilter.toLowerCase())) {
							return ingredient;
						}
					})
					.map((ingredient) => {
						amountOfResultsAferFilter.push(ingredient);
						return (
							<Grid.Column key={ingredient.id} mobile={16} tablet={8} computer={5} widescreen={4}>
								<Card fluid>
									<Card.Content>
										<Image
											src={ingredient.flavor.icon}
											size="mini"
											floated="right"
											style={{
												border: `2px solid ${ingredient.flavor.color}`,
												borderRadius: '4px',
												background: `${ingredient.flavor.color}`,
											}}
										/>
										<Card.Header>{ingredient.name}</Card.Header>
										<Card.Meta>{ingredient.flavor.type}</Card.Meta>

										<Card.Description style={{ marginTop: '1rem' }}>
											<div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
												<strong>Max: </strong>
												<span>{ingredient.flavor.maxPercentage}%</span>
											</div>
										</Card.Description>

										<Card.Description>
											<div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
												<strong>Allowed in: </strong>
												<span>{ingredient.flavor.allowedIn}</span>
											</div>
										</Card.Description>
									</Card.Content>

									<Card.Content>
										<Button size="mini" basic color="grey" floated="left" onClick={() => editIngredients(ingredient)}>
											Edit nutrition
										</Button>
										<Button
											size="mini"
											basic
											color="blue"
											floated="right"
											onClick={() => editFlavor(ingredient.flavor)}
										>
											Edit flavor
										</Button>
									</Card.Content>
								</Card>
							</Grid.Column>
						);
					})}

				<Container textAlign="center">
					{all.length > 0 && amountOfResultsAferFilter.length === 0 && (
						<Message icon="search" header="No results" content="No results found for your search query" />
					)}
				</Container>
			</Grid>
		</div>
	);
}

export default Ingredient;
