import React, { useState } from 'react';
import { Dialog } from 'primereact/dialog';
import { Dropdown } from 'primereact/dropdown';
import { InputNumber } from 'primereact/inputnumber';
import { InputText } from 'primereact/inputtext';
import { classNames } from 'primereact/utils';
import { Button } from 'primereact/button';

import ApiService from '../services/ApiService';
import { useAuth } from '../context/auth';
import RequiredMessage from './RequiredMessage';

const PromotionDialog = ({ promotionDialog, submittedPromotion, setSubmittedPromotion, setPromotionDialog, toast }) => {
	const api = new ApiService();
	const { authTokens } = useAuth();

	const [promotion, setPromotion] = useState({
		type: '',
		value: '',
		unit: '',
	});

	const savePromotion = () => {
		setSubmittedPromotion(true);

		if (!promotion) return;

		if (promotion.type.trim() && promotion.value && promotion.unit.trim()) {
			try {
				const headers = {
					'X-Firebase-Auth-Client': authTokens.token,
				};

				console.log('promotion', promotion);

				let promotionObjForApi = {
					type: promotion.type,
					value: promotion.value,
					unit: promotion.unit,
				};

				api.createPromotion(promotionObjForApi, headers);

				setPromotionDialog(false);

				toast.current.show({
					severity: 'success',
					summary: 'Successful',
					detail: 'Promotion Created',
					life: 3000,
				});
			} catch (error) {
				console.error(error);

				toast.current.show({
					severity: 'error',
					summary: 'Error',
					detail: 'Promotion Creation Failed',
					life: 3000,
				});
			}
		} else {
			toast.current.show({
				severity: 'error',
				summary: 'Validation Error',
				detail: 'Fields cannot be empty',
				life: 3000,
			});
		}
	};

	const hideDialogPromotion = () => {
		setSubmittedPromotion(false);
		setPromotionDialog(false);
	};

	const dialogFooter = (
		<>
			<Button label="Cancel" icon="pi pi-times" outlined onClick={hideDialogPromotion} />
			<Button label="Save" icon="pi pi-check" onClick={savePromotion} />
		</>
	);

	return (
		<>
			<Dialog
				visible={promotionDialog}
				style={{ width: '32rem' }}
				breakpoints={{ '960px': '75vw', '641px': '90vw' }}
				header="New promotion"
				modal
				className="p-fluid"
				footer={dialogFooter}
				onHide={hideDialogPromotion}
			>
				<div className=" field col">
					<label htmlFor="type" className="font-bold block">
						Type
					</label>
					<Dropdown
						id="type"
						value={promotion.type}
						options={[
							{ key: 'FREE', text: 'FREE', value: 'FREE' },
							{ key: 'REDUCTION', text: 'REDUCTION', value: 'REDUCTION' },
						]}
						onChange={(e) => {
							setPromotion({
								...promotion,
								type: e.value,
							});
						}}
						placeholder="Select a type"
						optionLabel="text"
						required
					/>

					{submittedPromotion && !promotion.type && <RequiredMessage message="Type is required." />}
				</div>
				<div className="formgrid grid">
					<div className=" field col">
						<label htmlFor="value" className="font-bold block">
							Value
						</label>
						<InputNumber
							id="value"
							value={promotion.value}
							onValueChange={(e) => {
								setPromotion({
									...promotion,
									value: e.value,
								});
							}}
							showButtons
							buttonLayout="horizontal"
							decrementButtonClassName="p-button-secondary"
							incrementButtonClassName="p-button-secondary"
							incrementButtonIcon="pi pi-plus"
							decrementButtonIcon="pi pi-minus"
							step={1}
							required
						/>
						{submittedPromotion && !promotion.value && <RequiredMessage message="Value is required." />}
					</div>

					<div className=" field col">
						<label htmlFor="unit" className="font-bold block">
							Unit
						</label>
						<InputText
							id="unit"
							value={promotion.unit}
							onChange={(e) => {
								setPromotion({
									...promotion,
									unit: e.target.value,
								});
							}}
							required
							autoFocus
							className={classNames({ 'p-invalid': submittedPromotion && !promotion.unit })}
						/>
						{submittedPromotion && !promotion.unit && <RequiredMessage message="Unit is required." />}
					</div>
				</div>
			</Dialog>
		</>
	);
};

export default PromotionDialog;
