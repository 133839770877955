import React, { useContext } from 'react'
import { Container, Loader } from 'semantic-ui-react'
import LoadingContext from './LoadingContext'

const Spinner = () => {
  const { showSpinner } = useContext(LoadingContext)

  return (
        <Container>
        { showSpinner &&
            <Loader active={showSpinner} />
        }
        </Container>
  )
}

export default Spinner
