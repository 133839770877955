import { useCallback, useContext } from 'react'
import { useAuth } from '../context/auth';
import ErrorContext from './ErrorContext'
import LoadingContext from '../loading/LoadingContext'

const useQuery = () => {
  const { setErrorMessage } = useContext(ErrorContext)
  const { showLoading, hideLoading } = useContext(LoadingContext)
  const { authTokens } = useAuth();
  const defaultHeaders = {
        'Content-Type': 'application/json',
        'X-Firebase-Auth-Client':  authTokens.token
    }

  const execute = async (url, options) => {
    options.headers = {...options.headers, ...defaultHeaders}
    showLoading()
    setErrorMessage({ errorMessage: '' })
    try {
      const response = await fetch(url, options)
      if (!response.ok) {
        throw response
      } else {
        if (response.status === 201){
          return response
        }
        const json = await response.json()
        if (json.redirect) {
          window.location.href = json.redirect
        }
        return json
      }
    } catch (error) {
        if (error){
            const message = error
            setErrorMessage({ errorMessage: message.message })
        }
    } finally {
      hideLoading()
    }
  }

  return {
    execute: useCallback(execute, [])
  }
}

export default useQuery
