import React, { useEffect, useRef, useState } from 'react';
import { useFormik } from 'formik';
import { InputText } from 'primereact/inputtext';
import { InputTextarea } from 'primereact/inputtextarea';
import { MultiSelect } from 'primereact/multiselect';
import { Dropdown } from 'primereact/dropdown';
import { Button } from 'primereact/button';
import { Toast } from 'primereact/toast';
import { classNames } from 'primereact/utils';

import './Recipe.css';
import validationSchema from './validationSchema';
import ApiService from '../services/ApiService';
import { useAuth } from '../context/auth';
import useQuery from '../utils/useQuery';
const api = new ApiService();

const RecipeCreator = (props) => {
	const { authTokens } = useAuth();
	const { execute } = useQuery();

	const toast = useRef(null);

	const [loading, setLoading] = useState(false);

	const [allFlavors, setAllFlavors] = useState([]);
	const [charCountDescription, setCharCountDescription] = useState(0);
	const maxCharCountDescription = 255;

	const [percentageSum, setPercentageSum] = useState(0);
	const [areFlavorIdsUnique, setAreFlavorIdsUnique] = useState(true);

	const [imageBlob, setImageBlob] = useState(null);
	const [iconBlob, setIconBlob] = useState(null);

	const [recipeTypeSettings, setRecipeTypeSettings] = useState([]);

	useEffect(() => {
		// if initial values are passed in, also update the char count
		if (props.location.state && props.location.state.description) {
			setCharCountDescription(props.location.state.description.length);
		}
	}, [props.location.state]);

	useEffect(() => {
		const headers = {
			'X-Firebase-Auth-Client': authTokens.token,
		};

		// get all flavors
		const getAllFlavors = async () => {
			try {
				const result = await api.getFlavors(headers);
				// loop through flavors and create an array of objects with the key, text, and value properties for the dropdown
				const flavorOptions = result.data.map((flavor) => {
					return {
						key: flavor.id,
						text: flavor.name,
						value: flavor.id,
					};
				});

				setAllFlavors(flavorOptions);
			} catch (error) {
				console.log('error', error);

				toast.current.show({
					severity: 'error',
					summary: 'Error Message',
					detail: 'Error getting flavors',
					life: 3000,
				});
			}
		};

		getAllFlavors();

		// cleanup
		return () => {
			setAllFlavors([]);
		};
	}, []);

	const formik = useFormik({
		enableReinitialize: true,
		initialValues: {
			recipeName: props.location?.state?.name || '',
			description: props.location?.state?.description || '',
			category: props.location?.state?.categories || [],
			smoothieParts: props.location.state?.smoothieParts.map((part) => {
				return { flavorId: part.flavor.id, percentage: part.percentage };
			}) || [{ flavorId: '', percentage: 0 }],
			waterRatio: props.location?.state?.waterRatio || 0,
			image: props.location?.state?.image || '',
			icon: props.location?.state?.badgeIconUrl || '',
			weightRatio: props.location?.state?.weightRatio || 0,
			steamConfiguration: props.location?.state?.steamConfiguration || 30000,
			blendConfiguration: props.location?.state?.blendConfiguration || 30000,
		},
		validationSchema: validationSchema,
		onSubmit: async (values) => {
			setLoading(true);
			let awsImage = formik.values.image;
			let awsIcon = formik.values.icon;
			const baseUrl = api.getBaseUrl();

			if (imageBlob) {
				awsImage = await uploadImages(imageBlob, formik.values.recipeName);

				// cleanup
				setImageBlob(null);
			}

			if (iconBlob) {
				awsIcon = await uploadImages(iconBlob, formik.values.recipeName);

				// cleanup
				setIconBlob(null);
			}

			const recipe = {
				name: formik.values.recipeName,
				description: formik.values.description,
				image: awsImage,
				smoothieParts: formik.values.smoothieParts.map((part) => {
					return { flavorId: part.flavorId, percentage: part.percentage };
				}),
				categories: formik.values.category,
				badgeIconUrl: awsIcon,
				waterRatio: formik.values.waterRatio,
				weightRatio: formik.values.weightRatio,
				steamConfiguration: formik.values.steamConfiguration,
				blendConfiguration: formik.values.blendConfiguration,
			};

			try {
				if (props.location.state) {
					const updateOptions = {
						method: 'PUT',
						body: JSON.stringify(recipe),
					};
					await execute(`${baseUrl}/api/recipes/${props.location.state.id}`, updateOptions);
				} else {
					const createOptions = {
						method: 'POST',
						body: JSON.stringify(recipe),
					};
					await execute(`${baseUrl}/api/recipes`, createOptions);
				}

				toast.current.show({
					severity: 'success',
					summary: props.location.state ? 'Recipe Updated' : 'Recipe Created',
					detail: props.location.state ? 'Recipe has been updated' : 'Recipe has been created',
					life: 3000,
				});

				// cleanup
				setLoading(false);
			} catch (error) {
				console.log('error', error);

				toast.current.show({
					severity: 'error',
					summary: 'Error Message',
					detail: 'Error creating recipe',
					life: 3000,
				});

				// cleanup
				setLoading(false);
			} finally {
				// cleanup
				setImageBlob(null);
				setIconBlob(null);
			}
		},
	});

	const uploadImages = async (data, name) => {
		if (data !== undefined) {
			const headers = {
				'X-Firebase-Auth-Client': authTokens.token,
			};

			const formData = new FormData();
			formData.append('file', data);
			formData.append('name', name); // recipe name
			formData.append('type', 'recipes');

			const result = await api.uploadFile(formData, headers);

			if (result.status === 200) {
				return result.data;
			} else {
				toast.current.show({
					severity: 'error',
					summary: 'Error Message',
					detail: 'Error uploading image',
					life: 10000,
				});
			}
		}
	};

	useEffect(() => {
		// Calculate the sum of percentages whenever formik.values.smoothieParts changes
		const sum = formik.values.smoothieParts.reduce((acc, curr) => acc + Number(curr.percentage), 0);
		setPercentageSum(sum);

		// Check if flavorIds are unique
		const flavorIds = formik.values.smoothieParts.map((part) => part.flavorId);
		const uniqueFlavorIds = [...new Set(flavorIds)];
		setAreFlavorIdsUnique(flavorIds.length === uniqueFlavorIds.length);
	}, [formik.values.smoothieParts]);

	// Recipe type settings
	useEffect(() => {
		const fetchRecipeTypeSettings = async () => {
			try {
				const headers = {
					'X-Firebase-Auth-Client': authTokens.token,
				};

				const response = await api.getRecipeTypeSettings(headers);
				setRecipeTypeSettings(response.data);
			} catch (error) {
				toast.current.show({
					severity: 'error',
					summary: 'Error fetching recipe type settings',
					detail: 'Could not load recipe type settings',
					life: 3000,
				});
			}
		};

		fetchRecipeTypeSettings();
	}, [authTokens.token]);

	useEffect(() => {
		// Check if the category includes "ORIGINAL"
		const hasOriginal = formik.values.category.some((category) => category.toUpperCase() === 'ORIGINAL');

		// If category does not include "ORIGINAL" and recipeTypeSettings is not empty
		if (!hasOriginal && recipeTypeSettings.length > 0) {
			formik.values.category.forEach((category) => {
				console.log('Current category: ', category);
				const settings = recipeTypeSettings.find(
					(setting) => setting.recipeCategory.toUpperCase() === category.toUpperCase(),
				);
				console.log('Settings for category: ', settings);
				if (settings) {
					formik.setFieldValue('waterRatio', settings.waterRatio);
					formik.setFieldValue('weightRatio', settings.weightRatio);
				}
			});
		}
	}, [formik.values.category, recipeTypeSettings]);

	return (
		<div>
			<Toast ref={toast} />

			<form onSubmit={formik.handleSubmit} style={{ maxWidth: '800px', margin: '0 auto' }}>
				<div>
					<h2 className="my-6">Details</h2>
				</div>

				<div className="p-fluid p-formgrid p-grid">
					{/* image
						1. show image if there is one
						2. show upload button if there is no image
						3. show remove button if there is an image
					*/}

					<div className="flex gap-3">
						<div className="flex-1">
							<label htmlFor="">Image</label>

							{formik.values.image ? (
								<>
									<div className="uploadedImage border-round border-1 border-bluegray-300 bg-gray-100 flex align-items-center justify-content-center relative">
										<img alt="Uploaded file" src={formik.values.image} className="p-mb-2 uploadedImage-image" />

										<div className="p-d-flex p-ai-center p-jc-center">
											<Button
												type="button"
												icon="pi pi-trash"
												className="absolute p-button-danger"
												style={{ top: '1rem', right: '1rem' }}
												onClick={() => {
													formik.setFieldValue('image', '');
												}}
											/>
										</div>
									</div>
								</>
							) : (
								<div
									className={`border-round relative flex flex-column align-items-center justify-content-center uploadedImage placeholderImage ${
										formik.touched.image && formik.errors.image
											? 'border-1 border-red-500 bg-red-100'
											: 'border-0 bg-bluegray-100'
									}`}
								>
									<div className="flex flex-column align-items-center justify-content-center">
										<span className="block">
											<i className="pi pi-image" style={{ fontSize: '3rem', color: '#9ca3af' }} />
										</span>
										<span className="text-gray-700 block">No image</span>
									</div>

									<div className="">
										<input
											className="p-inputtext p-component"
											style={{ display: 'none' }}
											id="image"
											name="image"
											type="file"
											accept="image/*"
											onChange={(event) => {
												event.persist();
												const file = event.currentTarget.files[0];
												const imageBlob = event.target.files[0];
												if (file) {
													const imageUrl = URL.createObjectURL(file);
													formik.setFieldValue('image', imageUrl);
												}

												setImageBlob(imageBlob);
											}}
										/>
										<label
											htmlFor="image"
											className="p-button p-button-text block text-indigo-500 "
											style={{ cursor: 'pointer' }}
										>
											<i className="pi pi-plus" /> Upload
										</label>
									</div>
								</div>
							)}

							{formik.touched.image && formik.errors.image && (
								<small id="username2-help" className="p-error">
									<i className="pi pi-exclamation-triangle mr-1" />
									{formik.errors.image}
								</small>
							)}
						</div>
						<div className="flex-1">
							<label htmlFor="">Icon</label>

							{formik.values.icon ? (
								<>
									<div className="uploadedImage border-round border-1 border-bluegray-300 bg-gray-100 flex align-items-center justify-content-center relative">
										<img alt="Uploaded file" src={formik.values.icon} className="p-mb-2 uploadedImage-image" />

										<div className="p-d-flex p-ai-center p-jc-center">
											<Button
												type="button"
												icon="pi pi-trash"
												className="absolute p-button-danger"
												style={{ top: '1rem', right: '1rem' }}
												onClick={() => {
													formik.setFieldValue('icon', '');
												}}
											/>
										</div>
									</div>
								</>
							) : (
								<div
									className={`border-round bg-bluegray-100 relative flex flex-column align-items-center justify-content-center uploadedImage placeholderImage ${
										formik.touched.icon && formik.errors.icon ? 'border-1 border-red-500' : 'border-0'
									}`}
								>
									<div className="flex flex-column align-items-center justify-content-center">
										<span className="block">
											<i className="pi pi-image" style={{ fontSize: '3rem', color: '#9ca3af' }} />
										</span>
										<span className="text-gray-700 block">No icon</span>
									</div>

									<div className="">
										<input
											className="p-inputtext p-component"
											style={{ display: 'none' }}
											id="icon"
											name="icon"
											type="file"
											accept="image/*"
											onChange={(event) => {
												event.persist();
												const file = event.currentTarget.files[0];
												const iconBlob = event.target.files[0];
												if (file) {
													const imageUrl = URL.createObjectURL(file);
													formik.setFieldValue('icon', imageUrl);
												}

												setIconBlob(iconBlob);
											}}
										/>
										<label
											htmlFor="icon"
											className="p-button p-button-text block text-indigo-500 "
											style={{ cursor: 'pointer' }}
										>
											<i className="pi pi-plus" /> Upload
										</label>
									</div>
								</div>
							)}
						</div>
					</div>

					<div className="p-field p-col-12 p-md-6 mt-5">
						<label htmlFor="recipeName">Recipe name</label>
						<InputText
							id="recipeName"
							name="recipeName"
							placeholder="Enter recipe name"
							type="text"
							onChange={formik.handleChange}
							onBlur={formik.handleBlur}
							value={formik.values.recipeName}
							className={classNames({ 'p-invalid': formik.touched.recipeName && formik.errors.recipeName })}
						/>
						{formik.touched.recipeName && formik.errors.recipeName && (
							<small id="username2-help" className="p-error">
								<i className="pi pi-exclamation-triangle mr-1" />
								{formik.errors.recipeName}
							</small>
						)}
					</div>

					<div className="p-field p-col-12 p-md-6 mt-3">
						<label htmlFor="description" className="flex align-items-center justify-content-between">
							Description
							<small
								id="username2-help"
								className={`p-info ${charCountDescription >= maxCharCountDescription ? 'p-error' : ''} float-right`}
							>
								{charCountDescription}/{maxCharCountDescription}
							</small>
						</label>
						<InputTextarea
							id="description"
							name="description"
							placeholder="Enter description"
							type="text"
							onChange={(e) => {
								setCharCountDescription(e.target.value.length);
								formik.handleChange(e);
							}}
							onBlur={formik.handleBlur}
							rows={5}
							cols={30}
							autoResize
							value={formik.values.description}
							className={classNames({ 'p-invalid': formik.touched.description && formik.errors.description })}
						/>

						{formik.touched.description && formik.errors.description && (
							<small id="username2-help" className="p-error">
								<i className="pi pi-exclamation-triangle mr-1" />
								{formik.errors.description}
							</small>
						)}
					</div>

					<div className="flex align-items-center gap-3 mt-3">
						<div className="flex-1">
							<label htmlFor="category">Category</label>
							{recipeTypeSettings.length > 0 ? (
								<MultiSelect
									id="category"
									name="category"
									placeholder="Select a category"
									display="chip"
									value={formik.values.category}
									optionLabel="name"
									options={[
										...recipeTypeSettings.map((setting, index) => ({
											key: `${setting.recipeCategory}-${index}`,
											name: setting.recipeCategory.charAt(0) + setting.recipeCategory.slice(1).toLowerCase(),
											value: setting.recipeCategory,
										})),
										{ key: 'original', name: 'Original', value: 'ORIGINAL' },
										{ key: 'test', name: 'Test', value: 'TEST' },
									]}
									showSelectAll={false}
									onChange={formik.handleChange}
									onBlur={formik.handleBlur}
									className={classNames({ 'p-invalid': formik.touched.category && formik.errors.category })}
									panelHeaderTemplate={(options) => {
										const value = formik.values.category;
										const label = `${value.length === 0 ? 'Select a category' : `${value.length} selected`} selected`;
										return (
											<>
												{value.length > 0 && (
													<div className="border-round-top flex justify-content-between align-content-center pl-4 mt-2">
														<div className="flex justify-content-center align-items-center">{label}</div>

														<div>
															<Button
																icon="pi pi-times"
																className="p-button-text"
																onClick={(e) => {
																	formik.setFieldValue('category', []);
																}}
															/>
														</div>
													</div>
												)}
											</>
										);
									}}
								/>
							) : (
								<div>No recipe type settings found</div>
							)}

							{formik.touched.category && formik.errors.category && (
								<small id="username2-help" className="p-error">
									<i className="pi pi-exclamation-triangle mr-1" />
									{formik.errors.category}
								</small>
							)}
						</div>

						<div className="flex-1">
							<label htmlFor="waterRatio">Water ratio</label>
							<InputText
								id="waterRatio"
								name="waterRatio"
								placeholder="Enter water ratio"
								type="number"
								step="0.1"
								onChange={formik.handleChange}
								onBlur={formik.handleBlur}
								value={formik.values.waterRatio}
								className={classNames({
									'p-invalid': formik.touched.waterRatio && formik.errors.waterRatio,
									'disabled-input':
										!formik.values.category.includes('ORIGINAL') && !formik.values.category.includes('TEST'),
								})}
								disabled={!formik.values.category.includes('ORIGINAL') && !formik.values.category.includes('TEST')}
							/>
							{formik.touched.waterRatio && formik.errors.waterRatio && (
								<small id="username2-help" className="p-error">
									<i className="pi pi-exclamation-triangle mr-1" />
									{formik.errors.waterRatio}
								</small>
							)}
						</div>
					</div>

					<div className="flex flex-wrap gap-3 mt-3">
						<div className="flex-auto md:flex-1">
							<label htmlFor="weightRatio">Weight Ratio</label>
							<InputText
								id="weightRatio"
								name="weightRatio"
								type="number"
								step="0.1"
								value={formik.values.weightRatio}
								onChange={formik.handleChange}
								onBlur={formik.handleBlur}
								className={classNames({
									'p-invalid': formik.touched.weightRatio && formik.errors.weightRatio,
									'disabled-input':
										!formik.values.category.includes('ORIGINAL') && !formik.values.category.includes('TEST'),
								})}
								disabled={!formik.values.category.includes('ORIGINAL') && !formik.values.category.includes('TEST')}
							/>

							{formik.touched.weightRatio && formik.errors.weightRatio && (
								<small id="username2-help" className="p-error">
									<i className="pi pi-exclamation-triangle mr-1" />
									{formik.errors.weightRatio}
								</small>
							)}
						</div>
						<div className="flex-auto md:flex-1">
							<label htmlFor="steamConfiguration">Steam Configuration (ms)</label>
							<InputText
								id="steamConfiguration"
								name="steamConfiguration"
								value={formik.values.steamConfiguration}
								onChange={formik.handleChange}
								onBlur={formik.handleBlur}
								className={classNames({
									'p-invalid': formik.touched.steamConfiguration && formik.errors.steamConfiguration,
								})}
								type="number"
							/>

							{formik.touched.steamConfiguration && formik.errors.steamConfiguration && (
								<small id="username2-help" className="p-error">
									<i className="pi pi-exclamation-triangle mr-1" />
									{formik.errors.steamConfiguration}
								</small>
							)}
						</div>
						<div className="flex-auto md:flex-1">
							<label htmlFor="blendConfiguration">Blend Configuration (ms)</label>
							<InputText
								id="blendConfiguration"
								name="blendConfiguration"
								value={formik.values.blendConfiguration}
								onChange={formik.handleChange}
								onBlur={formik.handleBlur}
								className={classNames({
									'p-invalid': formik.touched.blendConfiguration && formik.errors.blendConfiguration,
								})}
								type="number"
							/>

							{formik.touched.blendConfiguration && formik.errors.blendConfiguration && (
								<small id="username2-help" className="p-error">
									<i className="pi pi-exclamation-triangle mr-1" />
									{formik.errors.blendConfiguration}
								</small>
							)}
						</div>
					</div>

					<div>
						<h2 className="my-6">Ingredients</h2>
					</div>

					<div className="p-field p-col-12 p-md-6 mt-3">
						<div className="flex align-items-center justify-content-between">
							<div>
								<label className="mr-3 flex flex-column" htmlFor="smoothieParts">
									{/* info to user: if smoothie that guideline is to not exceed 50% of an ingredient */}
									<span className="">Ingredients (Flavor and Percentage)</span>
									{formik.values.category.includes('SMOOTHIE') && (
										<small className="block text-blue-500 flex align-items-center">
											<span className="mr-1 flex align-items-center justify-content-center">
												<i className="pi pi-info-circle mr-1" />
											</span>
											<span>For smoothie recipe try to limit the percentage of each ingredient to 50%</span>
										</small>
									)}
								</label>

								{!areFlavorIdsUnique && (
									<small className="p-error">
										<i className="pi pi-exclamation-triangle mr-1" />
										Cannot have duplicate flavors
									</small>
								)}
							</div>

							{formik.values.smoothieParts.length > 0 && (
								<small id="username2-help" className="float-right">
									<span className={`${percentageSum === 100 ? 'text-green-600' : 'p-error'}`}>
										{percentageSum}/100 %
									</span>
									{formik.touched.smoothieParts && (
										<span className={`float-right ${percentageSum !== 100 ? 'p-error' : 'p-info'}`}>
											{percentageSum !== 100 && <span> - The sum of percentages must be 100</span>}
										</span>
									)}
								</small>
							)}
						</div>

						{formik.values.smoothieParts.map((part, index) => {
							return (
								<div key={index} className={`card ${formik.values.smoothieParts.length > 1 ? 'mt-2' : ''}`}>
									<div className="flex gap-3 align-items-center">
										<div className={`p-inputgroup ${formik.values.smoothieParts.length > 1 ? 'flex-2' : 'flex-1'}`}>
											<Dropdown
												className={classNames({
													'p-invalid':
														formik.touched.smoothieParts &&
														formik.touched.smoothieParts[index] &&
														formik.errors.smoothieParts &&
														formik.errors.smoothieParts[index] &&
														formik.errors.smoothieParts[index].flavorId,
												})}
												value={part.flavorId}
												options={allFlavors}
												onChange={(e) => {
													// Create a new array with the updated part
													const updatedSmoothieParts = formik.values.smoothieParts.map((part, partIndex) => {
														if (partIndex === index) {
															// Update the flavorId of the part at the current index
															return { ...part, flavorId: e.value };
														} else {
															// Leave the part unchanged
															return part;
														}
													});

													// Update the smoothieParts field value
													formik.setFieldValue('smoothieParts', updatedSmoothieParts, true);
												}}
												onBlur={formik.handleBlur}
												placeholder="Select a flavor"
												optionLabel="text"
												id="flavorId"
											/>
										</div>

										<div className={`p-inputgroup ${formik.values.smoothieParts.length > 1 ? 'flex-2' : 'flex-1'}`}>
											<InputText
												id="percentage"
												name="percentage"
												placeholder="Enter percentage"
												type="number"
												onChange={(e) => {
													// Create a new array with the updated part
													const updatedSmoothieParts = formik.values.smoothieParts.map((part, partIndex) => {
														if (partIndex === index) {
															// Update the percentage of the part at the current index
															return { ...part, percentage: e.target.value };
														} else {
															// Leave the part unchanged
															return part;
														}
													});

													// Update the smoothieParts field value
													formik.setFieldValue('smoothieParts', updatedSmoothieParts, true);
												}}
												onBlur={formik.handleBlur}
												value={part.percentage}
												className={classNames({
													'p-invalid':
														// check if the smoothieParts field has been touched + check if the percentage field at the current index has been touched + check if the percentage field at the current index has an error
														formik.touched.smoothieParts &&
														formik.touched.smoothieParts[index] &&
														formik.errors.smoothieParts &&
														formik.errors.smoothieParts[index] &&
														formik.errors.smoothieParts[index].percentage,
												})}
											/>
											<span className="p-inputgroup-addon">%</span>
										</div>

										{formik.values.smoothieParts.length > 1 && (
											<div className="p-inputgroup flex-1">
												<Button
													type="button"
													icon="pi pi-trash"
													className="p-button-danger p-button-rounded"
													onClick={() => {
														// Create a new array without the part at the current index
														const updatedSmoothieParts = formik.values.smoothieParts.filter(
															(part, partIndex) => partIndex !== index,
														);

														// Update the smoothieParts field value
														formik.setFieldValue('smoothieParts', updatedSmoothieParts);
													}}
												/>
											</div>
										)}
									</div>

									{formik.touched.smoothieParts &&
										formik.touched.smoothieParts[index] &&
										formik.touched.smoothieParts[index].flavorId &&
										formik.errors.smoothieParts &&
										formik.errors.smoothieParts[index] &&
										formik.errors.smoothieParts[index].flavorId && (
											<small id="username2-help" className="p-error block mt-1">
												<i className="pi pi-exclamation-triangle mr-1" />
												{formik.errors.smoothieParts[index].flavorId}
											</small>
										)}

									{formik.touched.smoothieParts &&
										formik.touched.smoothieParts[index] &&
										formik.touched.smoothieParts[index].percentage &&
										formik.errors.smoothieParts &&
										formik.errors.smoothieParts[index] &&
										formik.errors.smoothieParts[index].percentage && (
											<small id="username2-help" className="p-error">
												<i className="pi pi-exclamation-triangle mr-1" />
												{formik.errors.smoothieParts[index].percentage}
											</small>
										)}

									{formik.values.smoothieParts.length - 1 === index && formik.values.smoothieParts.length < 5 && (
										<div className="flex justify-content-end">
											<Button
												type="button"
												icon="pi pi-plus"
												label="Add"
												className="mt-2 w-fit p-button-secondary"
												onClick={() => {
													formik.setFieldValue('smoothieParts', [
														...formik.values.smoothieParts,
														{ flavorId: '', percentage: 0 },
													]);
												}}
											/>
										</div>
									)}
								</div>
							);
						})}
					</div>

					<div className="mt-5">
						<Button
							type="submit"
							disabled={loading}
							label={loading ? 'Loading...' : props.location.state ? 'Update' : 'Create'}
							icon={loading ? 'pi pi-spin pi-spinner' : props.location.state ? 'pi pi-check' : 'pi pi-plus'}
							className="w-fit"
							onClick={() => {
								// if formik has errors show user message that they need to resolve the errors
								if (Object.keys(formik.errors).length > 0) {
									toast.current.show({
										severity: 'error',
										summary: 'Error Message',
										detail: 'Resolve the field errors before submitting',
										life: 10000,
									});
								}
							}}
						/>
					</div>
				</div>
			</form>
		</div>
	);
};

export default RecipeCreator;
