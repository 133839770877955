import React, { useEffect, useRef, useState } from 'react';
import { Form, Input, Checkbox, Message, FormSelect, Divider, Header, Icon } from 'semantic-ui-react';
import { Button } from 'primereact/button';
import { Tag } from 'primereact/tag';
import { Toast } from 'primereact/toast';

import ApiService from '../services/ApiService';
import { useAuth } from '../context/auth';
import './MachineDetail.css';

const api = new ApiService();

const MachineDetail = (props) => {
	const toast = useRef(null);
	const typeOptions = [
		{ key: 'smoothie', value: 'SMOOTHIE', text: 'Smoothie' },
		{ key: 'soup', value: 'SOUP', text: 'Soup' },
		{ key: 'combo', value: 'COMBO', text: 'Combo' },
	];

	const themeOptions = [
		{ key: 'Alberts', value: 'Alberts', text: 'Alberts' },
		{ key: 'Alpro', value: 'Alpro', text: 'Alpro' },
	];

	const [theme, setTheme] = useState(); // 'Alberts' or 'Alpro
	const [name, setName] = useState();
	const [productionName, setProductionName] = useState();
	const [price, setPrice] = useState();
	const [currency, setCurrency] = useState();
	const [amountOfFruit, setAmountOfFruit] = useState();
	const [amountOfVegetables, setAmountOfVegetables] = useState();
	const [amountOfWater, setAmountOfWater] = useState();
	const [availableForCustomer, setAvailableForCustomer] = useState();
	const [type, setType] = useState();
	const [referenceIntake, setReferenceIntake] = useState();
	const [typeRIOptions, setTypeRIOptions] = useState([{ key: '', value: '', text: '' }]);
	const [steamDeviation, setSteamDeviation] = useState(0);
	const [blendDeviation, setBlendDeviation] = useState(0);
	const [cupConfigurationId, setCupConfigurationId] = useState('');
	const [cupConfigurationOptions, setCupConfigurationOptions] = useState([{ key: '', value: '', text: '' }]);
	const [soupOffsetPrice, setSoupOffsetPrice] = useState(0.0);

	const [success, setSuccess] = useState(false);
	const [error, setError] = useState(false);
	const [loading, setLoading] = useState(false);
	const [locationFullAddress, setLocationFullAddress] = useState('');
	const [selectedLocation, setSelectedLocation] = useState('');
	const [selectedLocationId, setSelectedLocationId] = useState('');
	const [locationId, setLocationId] = useState('');
	const [locationOptions, setLocationOptions] = useState([{ key: '', value: '', text: '' }]);
	const { authTokens, userRole } = useAuth();
	const onChangeDropDownType = (e, { value }) => {
		setType(value);
	};

	const onChangeRIDropDownType = (e, { value }) => {
		setReferenceIntake(value);
	};

	const onChangeThemeDropDownType = (e, { value }) => {
		setTheme(value);
	};

	const handleSubmit = async () => {
		setLoading(true);
		const headers = {
			'X-Firebase-Auth-Client': authTokens.token,
		};

		const machineId = props.location.state.machineId;

		try {
			await api.updateMachine(
				machineId,
				{
					name,
					price,
					currency,
					amountOfVegetables: amountOfVegetables,
					amountOfFruit: amountOfFruit,
					amountOfWater: amountOfWater,
					availableForCustomer,
					type,
					referenceIntakeId: referenceIntake.id,
					theme,
					steamDeviation,
					blendDeviation,
					cupConfigurationId,
					soupOffsetPrice,
				},
				headers,
			);
			setLoading(false);
			setSuccess(true);
		} catch (error) {
			setLoading(false);
			setError(true);
		} finally {
		}
	};

	useEffect(() => {
		const headers = {
			'X-Firebase-Auth-Client': authTokens.token,
		};

		const listReferenceIntakes = async () => {
			const refIntakeResponse = await api.listReferenceIntakes(headers);
			const options = refIntakeResponse.data.map((ri) => {
				return { key: ri, value: ri, text: ri.country };
			});
			setTypeRIOptions(options);
		};

		listReferenceIntakes();
	}, [authTokens.token]);

	useEffect(() => {
		setLoading(true);
		const machineId = props.location.state.machineId;
		const headers = {
			'X-Firebase-Auth-Client': authTokens.token,
		};

		const currentMachine = async () => {
			try {
				const machineResponse = await api.getMachine(machineId, headers);
				const machine = machineResponse.data;
				console.log('machine', machine);

				setName(machine.customerName ? machine.customerName : '');
				setProductionName(machine.name);
				setPrice(machine.pricing.original);
				setCurrency(machine.pricing.currency);
				setAmountOfFruit(machine.amountOfFruit);
				setAmountOfVegetables(machine.amountOfVegetables);
				setAmountOfWater(machine.amountOfWater);
				setAvailableForCustomer(machine.availableForCustomer);
				setType(machine.type);
				setReferenceIntake(machine.referenceIntake);
				setLocationFullAddress(machine.location?.fullAddress);
				setLocationId(machine.location?.locationId);
				setTheme(machine.theme ? machine.theme : 'Alberts');
				setSteamDeviation(machine.steamDeviation);
				setBlendDeviation(machine.blendDeviation);
				setCupConfigurationId(machine.cupConfigurationId);
				setSoupOffsetPrice(machine.soupOffsetPrice);
			} catch (error) {
				console.error('ERROR', error);
			} finally {
				setLoading(false);
			}
		};

		currentMachine();
	}, [props.location.state.machineId, authTokens.token]);

	const handleLocationSubmit = async () => {
		const headers = {
			'X-Firebase-Auth-Client': authTokens.token,
		};

		const machineId = props.location.state.machineId;

		try {
			await api.updateMachineLocation(machineId, { locationId: selectedLocationId }, headers);

			setLocationFullAddress(selectedLocation);
			toast.current.show({
				severity: 'success',
				summary: 'Success',
				detail: 'Location updated',
				life: 3000,
			});
		} catch (error) {
			console.error('ERROR', error);

			toast.current.show({
				severity: 'error',
				summary: 'Error',
				detail: 'Failed to update location',
				life: 3000,
			});
		}
	};

	useEffect(() => {
		const headers = {
			'X-Firebase-Auth-Client': authTokens.token,
		};

		const listLocations = async () => {
			const locationResponse = await api.getAllLocations(headers);
			const options = locationResponse.data.map((location) => {
				return { key: location.locationId, value: location.fullAddress, text: location.fullAddress };
			});
			setLocationOptions(options);
		};

		listLocations();

		return () => {
			setLocationOptions([]);
		};
	}, [authTokens.token]);

	const handleLocationSelect = (e, { value }) => {
		setSelectedLocation(value);
		const location = locationOptions.find((location) => location.value === value);
		setSelectedLocationId(location.key);
	};

	useEffect(() => {
		const headers = {
			'X-Firebase-Auth-Client': authTokens.token,
		};

		const getCupConfigurations = async () => {
			try {
				const response = await api.getAllCupConfigurations(headers);
				const cupConfigurations = response.data;
				// Update the cup configuration options
				const cupConfigurationOptions = cupConfigurations.map((cup) => ({
					key: cup.id,
					value: cup.id,
					text: `${cup.name} (Cup size: ${cup.cupSize} ml)`,
				}));
				setCupConfigurationOptions(cupConfigurationOptions);
			} catch (error) {
				// Handle error
				console.error('Error fetching cup configurations', error);
			}
		};

		getCupConfigurations();

		return () => {
			setCupConfigurationOptions([]);
		};
	}, [authTokens.token]);

	const onChangeCupConfiguration = (e, { value }) => {
		setCupConfigurationId(value);
	};

	return (
		<>
			<Toast ref={toast} />
			<section className="container-details">
				<div className="general-info">
					<h2>General information</h2>

					<div className="info-flex">
						<span>Production name</span>
						<span>{productionName}</span>
					</div>

					<div className="info-flex">
						<span>ID</span>
						<span>{props.location.state.machineId}</span>
					</div>

					{props.location.state.plcVersion && (
						<div className="info-flex">
							<span>PLC Version</span>
							<span>{props.location.state.plcVersion}</span>
						</div>
					)}

					{props.location.state.posVersion && (
						<div className="info-flex">
							<span>POS Version</span>
							<span>{props.location.state.posVersion}</span>
						</div>
					)}

					<div className="info-flex">
						<span>Available for customer</span>
						{props.location.state.availableForCustomer === true ? (
							<Tag severity="success" value={props.location.state.availableForCustomer.toString()} rounded></Tag>
						) : (
							<Tag severity="danger" value={props.location.state.availableForCustomer.toString()} rounded></Tag>
						)}
					</div>

					{props.location.state.ipOpenvpn && (
						<div className="info-flex">
							<span>IP Address</span>
							<a href={`http://${props.location.state.ipOpenvpn}`} target="_blank" rel="noopener noreferrer">
								{props.location.state.ipOpenvpn}
							</a>
						</div>
					)}

					{userRole === 'ADMINISTRATOR' ? (
						<Form onSubmit={handleLocationSubmit} className="mt-3 w-full m-0">
							<Form.Field>
								<FormSelect
									fluid
									options={locationOptions}
									placeholder="No location (select to add)"
									value={locationFullAddress}
									onChange={handleLocationSelect}
								/>
							</Form.Field>
							<Button className="" type="submit">
								Update location
							</Button>
						</Form>
					) : (
						<div className="info-flex">
							<span>Location</span>
							<span>{locationFullAddress}</span>
						</div>
					)}
				</div>

				<div className="container-form">
					<h2>Update machine</h2>

					<Form onSubmit={handleSubmit} success={success} loading={loading} error={error}>
						<Form.Group widths="equal">
							<Form.Field>
								<label>Customer name</label>
								<Input placeholder="Name" value={name} onChange={(e) => setName(e.target.value)} />
							</Form.Field>

							<Form.Field>
								<label>Available</label>
								<Checkbox
									label="Available for customer"
									toggle
									onClick={(e) => setAvailableForCustomer(!availableForCustomer)}
									checked={availableForCustomer}
									value={availableForCustomer}
								/>
							</Form.Field>
						</Form.Group>

						<Divider horizontal className="my-5">
							<Header as="h4">Pricing</Header>
						</Divider>

						<Form.Group widths="equal">
							<Form.Field>
								<label>Price</label>
								<Input placeholder="Price" value={price} onChange={(e) => setPrice(e.target.value)} />
							</Form.Field>
							<Form.Field>
								<label>Currency</label>
								<Input placeholder="Currency" value={currency} onChange={(e) => setCurrency(e.target.value)} />
							</Form.Field>

							<Form.Field>
								<label>Soup offset price</label>
								<Input
									placeholder="Soup offset price"
									value={soupOffsetPrice}
									onChange={(e) => setSoupOffsetPrice(e.target.value)}
									type="number"
									step="0.01"
								/>
							</Form.Field>
						</Form.Group>

						<Divider horizontal className="my-5">
							<Header as="h4">Settings</Header>
						</Divider>

						<Form.Group widths="equal">
							<Form.Field>
								<label>Amount of water</label>
								<Input
									placeholder="Amount of water"
									value={amountOfWater}
									onChange={(e) => setAmountOfWater(e.target.value)}
								/>
							</Form.Field>
							<Form.Field>
								<label>Amount of fruit</label>
								<Input
									placeholder="Amount of fruit"
									value={amountOfFruit}
									onChange={(e) => setAmountOfFruit(e.target.value)}
								/>
							</Form.Field>
							<Form.Field>
								<label>Amount of vegetables</label>
								<Input
									placeholder="Amount of vegetables"
									value={amountOfVegetables}
									onChange={(e) => setAmountOfVegetables(e.target.value)}
								/>
							</Form.Field>
						</Form.Group>

						<Form.Group widths="equal">
							<Form.Field>
								<label>Steam deviation</label>
								<Input
									placeholder="Steam deviation"
									value={steamDeviation}
									onChange={(e) => setSteamDeviation(e.target.value)}
									min="-10000"
									max="10000"
									type="number"
								/>
							</Form.Field>

							<Form.Field>
								<label>Blend deviation</label>
								<Input
									placeholder="Blend deviation"
									value={blendDeviation}
									onChange={(e) => setBlendDeviation(e.target.value)}
									min="-10000"
									max="10000"
									type="number"
								/>
							</Form.Field>

							<Form.Dropdown
								label="Cup Configuration"
								placeholder="Select Cup Configuration"
								search
								selection
								options={cupConfigurationOptions}
								onChange={onChangeCupConfiguration}
								value={cupConfigurationId}
							></Form.Dropdown>
						</Form.Group>
						<Form.Group widths="equal">
							<Form.Dropdown
								label="Machine type"
								placeholder="Type"
								search
								selection
								options={typeOptions}
								onChange={onChangeDropDownType}
								value={type}
							></Form.Dropdown>

							<Form.Dropdown
								label="Reference intake"
								placeholder="Reference intake"
								search
								selection
								options={typeRIOptions}
								onChange={onChangeRIDropDownType}
								value={referenceIntake}
							></Form.Dropdown>

							<Form.Dropdown
								label="UI Theme"
								placeholder="UI Theme"
								search
								selection
								options={themeOptions}
								onChange={onChangeThemeDropDownType}
								value={theme}
							></Form.Dropdown>
						</Form.Group>

						<Message success header="Completed" content="Machine updated" />
						<Message error header="Failed" content="Failed to update machine" />
						<Button type="submit">Submit</Button>
					</Form>
				</div>
			</section>
		</>
	);
};

export default MachineDetail;
