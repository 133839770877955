import React, { useEffect, useState } from 'react';
import { Form, Button, Dropdown, Grid, Input, Message, Icon } from 'semantic-ui-react';
import ApiService from '../services/ApiService';
import { useAuth } from '../context/auth';
import { useHistory } from 'react-router-dom';
const api = new ApiService();

const IngredientCreator = (props) => {
	const [flavors, setFlavors] = useState([]);
	const [success, setSuccess] = useState(false);
	const [error, setError] = useState(false);
	const [loading, setLoading] = useState(false);
	const [name, setName] = useState('');
	const [energy, setEnergy] = useState();
	const [protein, setProtein] = useState();
	const [fat, setFat] = useState();
	const [saturatedFat, setSaturatedFat] = useState();
	const [carbs, setCarbs] = useState();
	const [fiber, setFiber] = useState();
	const [sugar, setSugar] = useState();
	const [salt, setSalt] = useState();
	const [sodium, setSodium] = useState();
	const [magnesium, setMagnesium] = useState();
	const [potassium, setPotassium] = useState();
	const [folate, setFolate] = useState();
	const [vitamineA, setVitamineA] = useState();
	const [vitamineC, setVitamineC] = useState();
	const [vitamineD, setVitamineD] = useState();
	const [vitamineE, setVitamineE] = useState();
	const [vitamineK, setVitamineK] = useState();
	const [vitamineB6, setVitamineB6] = useState();
	const [vitamineB12, setVitamineB12] = useState();
	const [flavor, setFlavor] = useState();
	const [price, setPrice] = useState(0.0);
	const [steamConfiguration, setSteamConfiguration] = useState(30000);
	const [blendConfiguration, setBlendConfiguration] = useState(30000);
	const [waterRatio, setWaterRatio] = useState(1.0);
	const [allergens, setAllergens] = useState([]);
	const [maxLengthError, setMaxLengthError] = useState(false);
	const { authTokens } = useAuth();
	const headers = {
		'X-Firebase-Auth-Client': authTokens.token,
	};
	const history = useHistory();

	const onChangeDropDown = (e, { value }) => {
		setFlavor(value);
	};

	const addFlavor = (e, { value }) => {
		history.push('/flavor/create', { name: value });
	};

	const handleSubmit = async () => {
		setLoading(true);
		const nutritionalValues = {
			energy,
			protein,
			fat: fat,
			saturatedFat: saturatedFat,
			carbohydrate: carbs,
			fiber,
			sugars: sugar,
			salt: salt,
			sodium: sodium,
			magnesium,
			potassium,
			folate,
			vitamineA,
			vitamineC,
			vitamineD,
			vitamineE,
			vitamineK,
			vitamineB6,
			vitamineB12,
		};

		// if character length is greater than 16, set error to true and cancel submit
		if (name?.length > 16) {
			setMaxLengthError(true);
			setError(true);
			setLoading(false);
			return;
		}

		try {
			if (props.location.state) {
				await api.updateIngredient(
					props.location.state.id,
					{
						flavorId: flavor,
						name,
						nutritionalValues,
						price,
						allergens,
						steamConfiguration,
						blendConfiguration,
						waterRatio,
					},
					headers,
				);
			} else {
				await api.createIngredient(
					{
						flavorId: flavor,
						name,
						nutritionalValues,
						price,
						allergens,
						steamConfiguration,
						blendConfiguration,
						waterRatio,
					},
					headers,
				);
			}
			setSuccess(true);
		} catch (error) {
			setError(true);
		}
		setLoading(false);
	};

	const fetchFlavors = async () => {
		const flavorResult = await api.getFlavors(headers);
		const flavorOptions = flavorResult.data.map((flavor) => ({
			key: flavor.id,
			text: flavor.name,
			value: flavor.id,
		}));
		setFlavors(flavorOptions);
	};

	const fetchIngredient = async (id) => {
		const result = await api.getIngredient(id, headers);
		console.log('ingredient', result.data);
		setName(result.data.name);
		setEnergy(result.data.nutritionalValues.energy);
		setProtein(result.data.nutritionalValues.protein);
		setFat(result.data.nutritionalValues.fat);
		setSaturatedFat(result.data.nutritionalValues.saturatedFat);
		setCarbs(result.data.nutritionalValues.carbohydrate);
		setFiber(result.data.nutritionalValues.fiber);
		setSugar(result.data.nutritionalValues.sugars);
		setSalt(result.data.nutritionalValues.salt);
		setSodium(result.data.nutritionalValues.sodium);
		setMagnesium(result.data.nutritionalValues.magnesium);
		setPotassium(result.data.nutritionalValues.potassium);
		setFolate(result.data.nutritionalValues.folate);
		setVitamineA(result.data.nutritionalValues.vitamineA);
		setVitamineC(result.data.nutritionalValues.vitamineC);
		setVitamineD(result.data.nutritionalValues.vitamineD);
		setVitamineE(result.data.nutritionalValues.vitamineE);
		setVitamineK(result.data.nutritionalValues.vitamineK);
		setVitamineB6(result.data.nutritionalValues.vitamineB6);
		setVitamineB12(result.data.nutritionalValues.vitamineB12);
		setFlavor(result.data.flavor.id);
		setAllergens(result.data.allergens);
		setPrice(result.data.price);
		setSteamConfiguration(result.data.steamConfiguration);
		setBlendConfiguration(result.data.blendConfiguration);
		setWaterRatio(result.data.waterRatio);
	};

	useEffect(() => {
		if (props.location.state) {
			fetchIngredient(props.location.state.id);
		}
		fetchFlavors();
	}, []);

	const validateMaxLength = (e) => {
		setName(e.target.value);
		if (e.target.value.length > 16) {
			setMaxLengthError(true);
		} else {
			setMaxLengthError(false);
		}
	};

	return (
		<Grid padded>
			<Form loading={loading} onSubmit={handleSubmit} success={success} error={error}>
				<Form.Field>
					<label>Ingredient name</label>
					<Input placeholder="ingredient name" value={name} onChange={(e) => validateMaxLength(e)} />
					{maxLengthError && (
						<div className="ui pointing red basic label">
							<Icon name="exclamation triangle" />
							Ingredient name must be less than 16 characters
						</div>
					)}
				</Form.Field>
				<Form.Field>
					<label>Flavor</label>
					<Dropdown
						placeholder="Flavor"
						search
						selection
						allowAdditions
						additionPosition="bottom"
						options={flavors}
						onChange={onChangeDropDown}
						onAddItem={addFlavor}
						value={flavor}
					></Dropdown>
				</Form.Field>
				<Form.Group widths="equal">
					<Form.Field>
						<label>Energy (kcal)</label>
						<Input placeholder="energy (kcal)" value={energy} onChange={(e) => setEnergy(e.target.value)} />
					</Form.Field>
					<Form.Field>
						<label>Protein (g)</label>
						<Input placeholder="protein (g)" value={protein} onChange={(e) => setProtein(e.target.value)} />
					</Form.Field>
					<Form.Field>
						<label>Fat (g)</label>
						<Input placeholder="Fat (g)" value={fat} onChange={(e) => setFat(e.target.value)} />
					</Form.Field>
					<Form.Field>
						<label>Saturate fat (g)</label>
						<Input
							placeholder="Saturated fat (g)"
							value={saturatedFat}
							onChange={(e) => setSaturatedFat(e.target.value)}
						/>
					</Form.Field>
					<Form.Field>
						<label>Carbohydrate (g)</label>
						<Input placeholder="carbohydrate (g)" value={carbs} onChange={(e) => setCarbs(e.target.value)} />
					</Form.Field>
				</Form.Group>
				<Form.Group>
					<Form.Field>
						<label>Fiber (g)</label>
						<Input placeholder="fiber (g)" value={fiber} onChange={(e) => setFiber(e.target.value)} />
					</Form.Field>
					<Form.Field>
						<label>Salt (mg)</label>
						<Input placeholder="salt (mg)" value={salt} onChange={(e) => setSalt(e.target.value)} />
					</Form.Field>
					<Form.Field>
						<label>Sodium (mg)</label>
						<Input placeholder="sodium (mg)" value={sodium} onChange={(e) => setSodium(e.target.value)} />
					</Form.Field>
					<Form.Field>
						<label>Sugars (g)</label>
						<Input placeholder="sugars (g)" value={sugar} onChange={(e) => setSugar(e.target.value)} />
					</Form.Field>
					<Form.Field>
						<label>Magnesium (mg)</label>
						<Input placeholder="magnesium (mg)" value={magnesium} onChange={(e) => setMagnesium(e.target.value)} />
					</Form.Field>
				</Form.Group>
				<Form.Group>
					<Form.Field>
						<label>Potassium (mg)</label>
						<Input placeholder="potassium (mg)" value={potassium} onChange={(e) => setPotassium(e.target.value)} />
					</Form.Field>
					<Form.Field>
						<label>Folate (µg)</label>
						<Input placeholder="folate (µg)" value={folate} onChange={(e) => setFolate(e.target.value)} />
					</Form.Field>
					<Form.Field>
						<label>Vitamine A (µg)</label>
						<Input placeholder="vitamine A (µg)" value={vitamineA} onChange={(e) => setVitamineA(e.target.value)} />
					</Form.Field>
					<Form.Field>
						<label>Vitamine C (mg)</label>
						<Input placeholder="vitamine C (mg)" value={vitamineC} onChange={(e) => setVitamineC(e.target.value)} />
					</Form.Field>
					<Form.Field>
						<label>Vitamine D (µg)</label>
						<Input placeholder="vitamine D (µg)" value={vitamineD} onChange={(e) => setVitamineD(e.target.value)} />
					</Form.Field>
				</Form.Group>
				<Form.Group>
					<Form.Field>
						<label>Vitamine E (mg)</label>
						<Input placeholder="vitamine E (mg)" value={vitamineE} onChange={(e) => setVitamineE(e.target.value)} />
					</Form.Field>
					<Form.Field>
						<label>Vitamine K (µg)</label>
						<Input placeholder="vitamine K (µg)" value={vitamineK} onChange={(e) => setVitamineK(e.target.value)} />
					</Form.Field>
					<Form.Field>
						<label>Vitamine B6 (mg)</label>
						<Input placeholder="vitamine B6 (mg)" value={vitamineB6} onChange={(e) => setVitamineB6(e.target.value)} />
					</Form.Field>
					<Form.Field>
						<label>Vitamine B12 (µg)</label>
						<Input
							placeholder="vitamine B12 (µg)"
							value={vitamineB12}
							onChange={(e) => setVitamineB12(e.target.value)}
						/>
					</Form.Field>
				</Form.Group>

				<Form.Group>
					<Form.Field>
						<label>Allergens</label>
						<Input
							placeholder="allergens"
							value={allergens.toString()}
							onChange={(e) => setAllergens(e.target.value.split(','))}
						/>
					</Form.Field>
					<Form.Field>
						<label>Price</label>
						<Input placeholder="€0.00" value={price} onChange={(e) => setPrice(e.target.value)} />
					</Form.Field>

					<Form.Field>
						<label>Water ratio (min 0.1, max 2.0)</label>
						<Input
							placeholder="water ratio"
							type="number"
							value={waterRatio}
							onChange={(e) => setWaterRatio(e.target.value)}
							min="0"
							max="2"
						/>
					</Form.Field>
				</Form.Group>

				<Form.Group>
					<Form.Field>
						<label>
							Steam configuration <small>(min 0, max 50 000)</small>
						</label>
						<Input
							type="number"
							min="0"
							max="50000"
							placeholder="steam configuration"
							value={steamConfiguration}
							onChange={(e) => setSteamConfiguration(e.target.value)}
						/>
					</Form.Field>

					<Form.Field>
						<label>
							Blend configuration <small>(min 0, max 50 000)</small>
						</label>
						<Input
							type="number"
							min="0"
							max="50000"
							placeholder="blend configuration"
							value={blendConfiguration}
							onChange={(e) => setBlendConfiguration(e.target.value)}
						/>
					</Form.Field>
				</Form.Group>
				<Message success header="Completed" content="New ingredient added" />
				<Message error header="Failed" content="Failed to add new ingredient" />
				<Button color="green" basic type="submit">
					Submit
				</Button>
			</Form>
		</Grid>
	);
};

export default IngredientCreator;
