import React, { useEffect, useState, createRef } from 'react';
import { Link } from 'react-router-dom';
import { TabMenu } from 'primereact/tabmenu';
import { Card, Container, Dropdown, Grid, Sticky, Icon, Input, Label, Menu, Message } from 'semantic-ui-react';
import { useAuth } from '../context/auth';
import ApiService from '../services/ApiService';
import './Machine.css';
import { useContext } from 'react';
import LoadingContext from '../loading/LoadingContext';
import Spinner from '../loading/Spinner';

import { Button } from 'primereact/button';

const api = new ApiService();

const Machine = () => {
	const contextRef = createRef();
	const options = [
		{ key: '1', text: 'All', value: '' },
		{ key: '2', text: 'Smoothie', value: 'SMOOTHIE' },
		{ key: '3', text: 'Soup', value: 'SOUP' },
		{ key: '4', text: 'Combo', value: 'COMBO' },
	];

	const statusOptions = [
		{ key: 'all', text: 'All', value: '' },
		{ key: 'online', text: 'Available for customer', value: 'online' },
		{ key: 'offline', text: 'Not available for customer', value: 'offline' },
	];

	const [machines, setMachines] = useState([]);
	const [searchFilter, setSearchFilter] = useState('');
	const [typeFilter, setTypeFilter] = useState('');
	const [statusFilter, setStatusFilter] = useState('');
	const { showLoading, hideLoading } = useContext(LoadingContext);
	const { authTokens } = useAuth();
	const [amountOfResults, setAmountOfResults] = useState();
	let amountOfResultsAferFilter = [];

	useEffect(() => {
		showLoading();
		const headers = {
			'X-Firebase-Auth-Client': authTokens.token,
		};
		const fetchAll = async () => {
			const response = await api.getMachines(headers);
			console.log(response.data);
			// sort on available for customer and alphabetically
			const machinesSorted = response.data.sort((a, b) => {
				if (a.availableForCustomer && !b.availableForCustomer) {
					return -1;
				} else if (!a.availableForCustomer && b.availableForCustomer) {
					return 1;
				} else {
					return a.name.localeCompare(b.name);
				}
			});

			setMachines(machinesSorted);
			hideLoading();
		};

		fetchAll();
	}, [authTokens]);

	const searchFilterMachines = async (e) => {
		setSearchFilter(e.target.value);
	};

	const handleFilterOntype = async (e, { value }) => {
		setTypeFilter(value);
	};

	const onHandleClearFilters = () => {
		setTypeFilter('');
		setStatusFilter('');
		setSearchFilter('');
	};

	useEffect(() => {
		setAmountOfResults(amountOfResultsAferFilter.length);
	}, [amountOfResultsAferFilter]);

	const items = [
		{ label: 'Machines', icon: 'pi pi-fw pi-home' },
		{
			label: 'Operations screen state',
			icon: 'pi pi-fw pi-cog',
			command: () => {
				window.location = '/machines/state';
			},
		},
	];

	return (
		<>
			<Spinner />

			<div ref={contextRef}>
				<div className="machine-sticky">
					<TabMenu model={items} />
				</div>

				<Grid padded stackable>
					<Grid.Row>
						<Grid.Column
							mobile={searchFilter !== '' || typeFilter !== '' || statusFilter !== '' ? 15 : 16}
							tablet={searchFilter !== '' || typeFilter !== '' || statusFilter !== '' ? 4 : 8}
							computer={searchFilter !== '' || typeFilter !== '' || statusFilter !== '' ? 3 : 4}
							style={{ marginBottom: '10px' }}
						>
							<Label color="black" size="large" style={{ float: 'left' }}>
								<Icon name="filter" />
								{amountOfResults} results
							</Label>
						</Grid.Column>

						<Grid.Column
							mobile={searchFilter !== '' || typeFilter !== '' || statusFilter !== '' ? 15 : 16}
							tablet={searchFilter !== '' || typeFilter !== '' || statusFilter !== '' ? 4 : 8}
							computer={searchFilter !== '' || typeFilter !== '' || statusFilter !== '' ? 3 : 4}
							style={{ marginBottom: '10px' }}
						>
							<Input
								style={{ width: '100%' }}
								value={searchFilter}
								icon="search"
								placeholder="Search..."
								onChange={(e) => searchFilterMachines(e)}
							/>
						</Grid.Column>

						<Grid.Column
							mobile={searchFilter !== '' || typeFilter !== '' || statusFilter !== '' ? 15 : 16}
							tablet={searchFilter !== '' || typeFilter !== '' || statusFilter !== '' ? 4 : 8}
							computer={searchFilter !== '' || typeFilter !== '' || statusFilter !== '' ? 3 : 4}
							style={{ marginBottom: '10px' }}
						>
							<Dropdown
								value={typeFilter}
								placeholder="Type"
								fluid
								selection
								options={options}
								onChange={handleFilterOntype}
							/>
						</Grid.Column>

						<Grid.Column
							mobile={searchFilter !== '' || typeFilter !== '' || statusFilter !== '' ? 15 : 16}
							tablet={searchFilter !== '' || typeFilter !== '' || statusFilter !== '' ? 4 : 8}
							computer={searchFilter !== '' || typeFilter !== '' || statusFilter !== '' ? 3 : 4}
							style={{ marginBottom: '10px' }}
						>
							<Dropdown
								value={statusFilter}
								placeholder="Status"
								fluid
								selection
								options={statusOptions}
								onChange={(e, { value }) => setStatusFilter(value)}
							/>
						</Grid.Column>

						{searchFilter !== '' || typeFilter !== '' || statusFilter !== '' ? (
							<Grid.Column
								mobile={searchFilter !== '' || typeFilter !== '' || statusFilter !== '' ? 15 : 16}
								tablet={searchFilter !== '' || typeFilter !== '' || statusFilter !== '' ? 6 : 8}
								computer={searchFilter !== '' || typeFilter !== '' || statusFilter !== '' ? 3 : 4}
								style={{ marginBottom: '10px' }}
							>
								<Button onClick={onHandleClearFilters} style={{ float: 'left' }}>
									Clear all filters
								</Button>
							</Grid.Column>
						) : null}
					</Grid.Row>

					<Grid.Row columns={1}>
						<Grid.Column
							className="machine-grid"
							stretched
							style={{
								border: '0.5px solid rgba(34,36,38,.15)',
								padding: '2rem 0',
								borderRadius: '0.5rem',
								backgroundColor: 'rgb(34 36 38 / 5%)',
							}}
						>
							<Card.Group stackable centered>
								{machines
									?.filter((machine) => {
										if (searchFilter === '') {
											return machine;
										} // search on machine.name and machine.customerName
										else if (
											machine.name.toLowerCase().includes(searchFilter.toLowerCase()) ||
											machine?.customerName?.toLowerCase().includes(searchFilter.toLowerCase())
										) {
											return machine;
										}
									})
									?.filter((machine) => {
										if (typeFilter === '') {
											return machine;
										} else if (machine.type === typeFilter) {
											return machine;
										}
									})
									?.filter((machine) => {
										if (statusFilter === '') {
											return machine;
										}

										// machine status is online when machine.availableForCustomer is true
										if (statusFilter === 'online' && machine.availableForCustomer) {
											return machine;
										} else if (statusFilter === 'offline' && !machine.availableForCustomer) {
											return machine;
										}
									})
									.map((machine) => {
										amountOfResultsAferFilter.push(machine);
										return (
											<div style={{ position: 'relative', margin: '0 0.5rem' }} key={machine.machineId}>
												{machine.ipOpenvpn && machine.isOpenvpn !== 'false' && machine.isOpenvpn !== 'true' && (
													<a
														href={`http://${machine.ipOpenvpn}`}
														target="_blank"
														rel="noopener noreferrer"
														style={{ position: 'absolute', top: '1.5rem', right: '0.5rem', zIndex: '6' }}
													>
														<Icon inverted circular link name="linkify" color="grey" />
													</a>
												)}

												<Card
													color={machine.availableForCustomer ? 'green' : 'red'}
													as={Link}
													style={{ margin: '0.5em 0' }}
													to={{ pathname: `/machines/${machine.machineId}`, state: machine }}
												>
													<Card.Content>
														<Card.Header textAlign="left" style={{ marginRight: '1.5rem' }}>
															{machine.customerName ? machine.customerName : machine.name}
														</Card.Header>
														<Card.Meta>{machine.customerName ? machine.name : ''}</Card.Meta>
														<Card.Description>{machine.type.toLowerCase()}</Card.Description>
													</Card.Content>
													<Card.Content extra>
														<div className="machine-flex">
															<Card.Description>
																<Icon name={machine.pricing.currency.toLowerCase()} />
																{machine.pricing.original}
															</Card.Description>
															<Card.Description>PLC: {machine.plcVersion}</Card.Description>
														</div>
													</Card.Content>
												</Card>
											</div>
										);
									})}
							</Card.Group>

							<Container textAlign="center">
								{machines.length > 0 && amountOfResultsAferFilter.length === 0 && (
									<Message icon="search" header="No results" content="No results found for your search query" />
								)}
							</Container>
						</Grid.Column>
					</Grid.Row>
				</Grid>
			</div>
		</>
	);
};

export default Machine;
