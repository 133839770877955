import React, { useEffect, useState } from 'react';
import { Form, Header, Table, TableBody, TableCell, TableRow, TableHeader, Icon, TableHeaderCell, Dropdown, Grid, GridColumn, Image, Message, Button, Label } from 'semantic-ui-react';
import ApiService from '../services/ApiService';
import './MachineCreator.css';
import { useAuth } from '../context/auth';
const api = new ApiService();

const MachineCreator = () => {

    const typeOptions = [
        { key: "smoothie", value: "SMOOTHIE", text: "Smoothie" },
        { key: "soup", value: "SOUP", text: "Soup" },
        { key: "combo", value: "COMBO", text: "Combo" }
    ];
    const [machineIngredients, setMachineIngredients] = useState([]);
    const [ingredients, setIngredients] = useState([]);
    const [name, setName] = useState("");
    const [password, setPassword] = useState("");
    const [amountOfFruitAndVegetables, setAmountOfFruitAndVegetables] = useState("");
    const [amountOfWater, setAmountOfWater] = useState("");
    const [price, setPrice] = useState("");
    const [dropdown, setDropdown] = useState([]);
    const [type, setType] = useState(typeOptions[0].value);
    const [success, setSuccess] = useState(false);
    const [error, setError] = useState(false);
    const [loading, setLoading] = useState(false);
    const { authTokens } = useAuth();
    const headers = {
        "X-Firebase-Auth-Client": authTokens.token
    }
    const cartridgeOptions = [
        { key: "1", value: "0", text: "Cartridge 1" },
        { key: "2", value: "1", text: "Cartridge 2" },
        { key: "3", value: "2", text: "Cartridge 3" },
        { key: "4", value: "3", text: "Cartridge 4" },
        { key: "5", value: "4", text: "Cartridge 5" },
        { key: "6", value: "5", text: "Cartridge 6" },
        { key: "7", value: "6", text: "Cartridge 7" },
        { key: "8", value: "7", text: "Cartridge 8" },
        { key: "9", value: "8", text: "Cartridge 9" },
        { key: "10", value: "9", text: "Cartridge 10" },
    ];

    const onChangeDropDown = (e, { value }) => {
        setDropdown(value);
    }

    const onChangeDropDownType = (e, { value }) => {
        setType(value);
    }

    const changeIngredients = (index, newIngredient, newIngredientIndex) => {
        setDropdown("");
        machineIngredients[index] = newIngredient;
        setMachineIngredients(machineIngredients);
    }

    const handleSubmit = async () => {
        setLoading(true);
        const ingredientIds = machineIngredients.map(ingredient => ingredient.id);
        try {
            await api.createMachine({ name, password, amountOfFruitAndVegetables, amountOfWater, price, ingredientIds, type }, headers);
            setLoading(false);
            setSuccess(true);

        } catch (error) {
            setLoading(false);
            setError(true);
        }
    }

    useEffect(() => {
        const fetchAll = async () => {
            const response = await api.getAllIngredients(headers);
            setIngredients(response.data);
        };
        fetchAll();
    }, []);


    return (
        <Grid columns={2}>

            <Form className="form" success={success} onSubmit={handleSubmit} loading={loading} error={error}>
                <Grid.Column width='5'>
                    <Header as='h1'>Create a Machine</Header>
                    <Form.Input label='Name' placeholder='Name' value={name} onChange={(e) => setName(e.target.value)}></Form.Input>
                    <Form.Input label='Enter Password' placeholder='password' type='password' value={password} onChange={(e) => setPassword(e.target.value)}></Form.Input>
                    <Form.Input label='Amount of fruit and vegetables (gram)' placeholder='160' value={amountOfFruitAndVegetables} onChange={(e) => setAmountOfFruitAndVegetables(e.target.value)} ></Form.Input>
                    <Form.Input label='Amount of water (gram)' placeholder='145' value={amountOfWater} onChange={(e) => setAmountOfWater(e.target.value)} ></Form.Input>
                    <Form.Input label='Price' placeholder='2.90' value={price} onChange={(e) => setPrice(e.target.value)}></Form.Input>
                    <Form.Dropdown
                        label='Machine type'
                        placeholder='Type'
                        search
                        selection
                        options={typeOptions}
                        onChange={onChangeDropDownType}
                        value={type}>
                    </Form.Dropdown>
                </Grid.Column>
                <GridColumn></GridColumn>
                <Form.Group widths={16} style={{ marginTop: 2 + 'em' }}>
                    <Form.Field>
                        <GridColumn width={8}>
                            <Header>Machine ingredients</Header>
                            <Table celled padded>
                                <TableHeader>
                                    <TableRow>
                                        <TableHeaderCell>Cartridge</TableHeaderCell>
                                        <TableHeaderCell>Ingredients</TableHeaderCell>
                                        <TableHeaderCell>Image</TableHeaderCell>
                                    </TableRow>
                                </TableHeader>
                                <TableBody>
                                    {machineIngredients.map((ingredient, index) => (
                                        <TableRow key={index}>
                                            <TableCell>{index + 1}</TableCell>
                                            <TableCell>{ingredient.name}</TableCell>
                                            <TableCell><Image src={ingredient.flavor.icon} size="mini"></Image></TableCell>
                                        </TableRow>

                                    ))}
                                </TableBody>
                            </Table>
                        </GridColumn>
                    </Form.Field>
                    <Form.Field>
                        <GridColumn width={8}>
                            <Header>All ingredients</Header>
                            <Table celled padded>
                                <TableHeader>
                                    <TableRow>
                                        <TableHeaderCell>Ingredients</TableHeaderCell>
                                        <TableHeaderCell>Image</TableHeaderCell>
                                        <TableHeaderCell>Actions</TableHeaderCell>
                                    </TableRow>
                                </TableHeader>
                                <TableBody>
                                    {ingredients.map((ingredient, index) => (
                                        <TableRow key={index}>
                                            <TableCell>{ingredient.name}</TableCell>
                                            <TableCell><Image src={ingredient.flavor.icon} size="mini"></Image></TableCell>
                                            <TableCell>
                                                <Dropdown
                                                    key={index}
                                                    clearable
                                                    selection
                                                    placeholder='Select a cartridge'
                                                    options={cartridgeOptions}
                                                    onChange={onChangeDropDown}
                                                />
                                                <Icon name='plus' onClick={() => changeIngredients(dropdown, ingredient, index)} />
                                            </TableCell>
                                        </TableRow>

                                    ))}
                                </TableBody>
                            </Table>
                        </GridColumn>
                    </Form.Field>
                </Form.Group>
                <Message
                    success
                    header='Completed'
                    content="New Machine added"
                />
                <Message
                    error
                    header='Failed'
                    content="Failed to add new machine"
                />
                <Button color='green' basic type='submit'>Submit</Button>

            </Form>
        </Grid>
    )
}

export default MachineCreator;