import React, { useEffect, useState } from 'react';
import { BrowserRouter, Switch, Route } from 'react-router-dom';
import { PrimeReactProvider, PrimeReactContext } from 'primereact/api';

import 'primereact/resources/themes/lara-light-indigo/theme.css';
import 'primereact/resources/primereact.min.css';
import 'primeicons/primeicons.css';

import './App.css';
import Login from './login/Login';
import PrivateRoute from './PrivateRoute';
import Navbar from './components/navbar/Navbar';
import Machine from './machine/Machine';
import MachineInfo from './machineInfo/MachineInfo';
import { AuthContext } from './context/auth';
import MachineCreator from './machineCreator/MachineCreator';
import IngredientCreator from './ingredient/IngredientCreator';
import FlavorCreator from './flavor/FlavorCreator';
import RecipeCreator from './recipe/RecipeCreator.jsx';
import Recipe from './recipe/Recipe';
import Code from './promotion/Code.jsx';
import MovieCreator from './movie/MovieCreator';
import { FirebaseAuthProvider } from '@react-firebase/auth';
import firebase from 'firebase/app';
import 'firebase/auth';
import { config } from './firebase-config.js';
import Settings from './settings/Settings';
import LoadingProvider from './loading/LoadingProvider';
import ErrorProvider from './utils/ErrorProvider';
import ReferenceIntake from './referenceIntake/ReferenceIntake';
import ReferenceIntakeCreator from './referenceIntake/ReferenceIntakeCreator';
import MachinePrtScState from './machine/MachinePrtScState';
import Ingredient from './ingredient/Ingredient';
import NavBarMobile from './components/navbar/NavBarMobile';
import ApiService from './services/ApiService';
import Movies from './movie/Movies';
import Locations from './Location/Locations.jsx';

const api = new ApiService();

function App({}) {
	const [navbarOpen, setNavbarOpen] = useState(true);
	const [authTokens, setAuthTokens] = useState(
		localStorage.getItem('access_token') !== 'undefined'
			? JSON.parse(localStorage.getItem('access_token'))
			: { token: '', claims: '' },
	);
	const [loginActive, setLoginActive] = useState(true);
	const [userRole, setUserRole] = useState();

	const setTokens = (data) => {
		setAuthTokens(data);
		localStorage.setItem('access_token', JSON.stringify(data));
	};

	useEffect(() => {
		if (authTokens) {
			const headers = {
				'X-Firebase-Auth-Client': authTokens.token,
			};

			const fetchUser = async () => {
				const response = await api.getCurrentUser(headers);

				setUserRole(response.data.role);
			};

			fetchUser();
		}
	}, [authTokens]);

	useEffect(() => {
		const unsubscribe = firebase.auth().onAuthStateChanged((user) => {
			if (user) {
				user
					.getIdTokenResult()
					.then((result) => {
						setTokens(result);
					})
					.catch((error) => {
						console.log(error);
					});
			} else {
				setTokens(null);
				if (window.location.pathname !== '/login') {
					window.location.href = '/login';
				}
			}
		});

		return () => {
			unsubscribe();
		};
	}, []);

	useEffect(() => {
		// calculate the expiration time of the token and set a timeout to sign out the user when the token expires
		if (authTokens) {
			const expirationTime = new Date(authTokens.expirationTime);
			const timeout = expirationTime.getTime() - new Date().getTime();

			if (timeout <= 0) {
				firebase.auth().signOut();
				return;
			}

			const signOutTimeout = setTimeout(() => {
				firebase.auth().signOut();
				setLoginActive(true);
			}, timeout);

			return () => {
				clearTimeout(signOutTimeout);
			};
		}
	}, [authTokens]);

	useEffect(() => {
		if (window.location.pathname === '/login') {
			setLoginActive(true);
		} else {
			setLoginActive(false);
		}
	}, [window.location.pathname, authTokens]);

	return (
		<FirebaseAuthProvider {...config} firebase={firebase}>
			<AuthContext.Provider value={{ authTokens, setAuthTokens: setTokens, userRole }}>
				<LoadingProvider>
					<ErrorProvider>
						<BrowserRouter>
							<PrimeReactProvider>
								<div className="flex-container">
									{authTokens && (
										<>
											<div className="nav-sticky">
												<Navbar userRole={userRole} />
											</div>

											<div className="hamburger">
												<NavBarMobile userRole={userRole} />
											</div>
										</>
									)}

									<div className={`${loginActive ? 'layout-container--login' : 'layout-container'}`}>
										<Switch>
											<PrivateRoute exact path="/" component={Machine}></PrivateRoute>
											<PrivateRoute exact path="/machines" component={Machine}></PrivateRoute>
											<PrivateRoute exact path="/machines/state" component={MachinePrtScState}></PrivateRoute>
											<PrivateRoute path="/machines/:id" component={MachineInfo}></PrivateRoute>
											<PrivateRoute exact path="/machine/create" component={MachineCreator}></PrivateRoute>
											<PrivateRoute exact path="/ingredients" component={Ingredient}></PrivateRoute>
											<PrivateRoute exact path="/ingredient/create" component={IngredientCreator}></PrivateRoute>
											<PrivateRoute exact path="/ingredient/update" component={IngredientCreator}></PrivateRoute>
											<PrivateRoute exact path="/flavor/create" component={FlavorCreator}></PrivateRoute>
											<PrivateRoute exact path="/flavor/update" component={FlavorCreator}></PrivateRoute>
											<PrivateRoute exact path="/recipe/create" component={RecipeCreator}></PrivateRoute>
											<PrivateRoute exact path="/recipe/update" component={RecipeCreator}></PrivateRoute>
											<PrivateRoute exact path="/recipe" component={Recipe}></PrivateRoute>
											<PrivateRoute exact path="/code" component={Code}></PrivateRoute>
											<PrivateRoute exact path="/movie/create" component={MovieCreator}></PrivateRoute>
											<PrivateRoute exact path="/settings" component={Settings}></PrivateRoute>
											<PrivateRoute exact path="/reference-intake" component={ReferenceIntake}></PrivateRoute>
											<PrivateRoute exact path="/movies" component={Movies}></PrivateRoute>
											<PrivateRoute
												exact
												path="/reference-intake/update"
												component={ReferenceIntakeCreator}
											></PrivateRoute>
											<PrivateRoute
												exact
												path="/reference-intake/create"
												component={ReferenceIntakeCreator}
											></PrivateRoute>
											<PrivateRoute exact path="/locations" component={Locations}></PrivateRoute>
											<Route path="/login" component={Login}></Route>
										</Switch>
									</div>
								</div>
							</PrimeReactProvider>
						</BrowserRouter>
					</ErrorProvider>
				</LoadingProvider>
			</AuthContext.Provider>
		</FirebaseAuthProvider>
	);
}

export default App;
